import React, {useState} from 'react';
import Drawer from "../../../../global/components/layout/Drawer";
import {useTranslation} from "react-i18next";
import {Button, Card, Flex, Typography, Modal} from "antd";
import InputFormField from "../../../../global/components/data_entry/form/InputFormField";
import {useRules} from "../../../../global/hooks/useRules";
import DatePickerFormField from "../../../../global/components/data_entry/form/DatePickerFormField";
import TextAreaFormField from "../../../../global/components/data_entry/form/TextAreaFormField";
import {CloseCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {neutral_2, neutral_5} from "../../../../global/utils/Colors";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import api from "axios";
import {useProjectManagementContext} from "../../pages/ProjectManagement/context/ProjectManagementContext";
import RequestSelectFormField from "../../../../global/components/data_entry/form/RequestSelectFormField";
import Form from "../../../../global/components/data_entry/form/Form";
import { DateFormat, DateFormatByUserTimezone, DateFormatUser } from '../../../../global/utils/dates';
import { useUserContext } from '../../../../global/context/UserContext';

const {Text} = Typography;
const {confirm} = Modal;

export default function FinancialMilestoneFormDrawer({open, setOpen, form}) {

    const {t} = useTranslation();
    const rules = useRules();
    const {IS_REQUIRED} = rules;
    const {project} = useProjectManagementContext()
    const [loading, setLoading] = useState("")

    const isEditing = !!form.getFieldValue("id")
    const milestoneEndDate = form.getFieldValue("end_date")
    const baselineCompletedDate = project?.forecast_conclude_baseline
    const forecastMode = project?.forecast_mode
    const {_user} = useUserContext();

    const handleSubmitMilestone = async (data) => {
        try{
            setLoading("save")
            data.project = project?.id
            if (isEditing){
                await api.patch(`project_management/forecast_milestone/${form.getFieldValue("id")}`, data)
            } else {
                await api.post(`project_management/forecast_milestone`, data)
            }
            try {
                if (project?.forecast_mode === "automatic") {
                    await api.post(`project_management/forecast_milestone/update_forecast_values_by_project/${project?.id}`)
                }
            } catch (error) {

            }
            dispatchEvent(new Event("refresh-investment-financial-planning-table"))
            setOpen(false);
            form.resetFields();
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteMilestone = async (data) => {
        try {
            setLoading("delete")
            await api.delete(`project_management/forecast_milestone/${form.getFieldValue("id")}`)
            dispatchEvent(new Event("refresh-investment-financial-planning-table"))
            setOpen(false);
            form.resetFields();
        } catch (error){
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false);
        }
    }

    const confirmDeleteMilestone = async (data) => {
        confirm({
            title: t("Delete Milestone"),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Yes"),
            okType: "danger",
            cancelText: t("No"),
            onOk: () => {void handleDeleteMilestone();},
        });
    }

    const isAfterMilestone = () => {
        return new Date(milestoneEndDate) <= new Date(baselineCompletedDate)
    }

    const getFormDisable = () => {
        if (!isEditing || !milestoneEndDate) return false
        return isAfterMilestone()
    }

    const getShowEstimatedValueForm = () => {
        const value = form.getFieldValue("estimated_value")
        return !!value || !baselineCompletedDate
    }

    return (
        <Drawer
            open={open}
            setOpen={setOpen}
            title={form.getFieldValue("name") || t("Title of the Financial Framework")}
            isGrayBody={true}
            footer={
                <Flex gap={4} justify="end">
                    {isEditing &&
                        <Button danger={true} loading={loading === "delete"} disabled={loading === "save"} onClick={() => confirmDeleteMilestone()}>
                            {t("Delete")}
                        </Button>
                    }
                    <Button type="primary" loading={loading === "save"} disabled={loading === "delete"} onClick={form.submit}>
                        {t("Save")}
                    </Button>
                </Flex>
            }
        >
            <Flex flex={1} gap={16} vertical={true}>

                <Card style={{background: neutral_2, borderColor: neutral_5}}>
                    <Flex vertical={true} gap={8}>
                        <Text strong={true}>{t("Why edit items that correlate with the financial framework?")}</Text>
                        <Text>
                            {t("The financial framework helps in providing a future financial outlook for the project, allowing for an estimate based on the baseline of financial resources and what has been spent to generate the forecast graph. Milestones can be deleted as long as they haven't been updated at the corresponding checkpoint")}
                        </Text>
                    </Flex>
                </Card>

                <Form layout="vertical" style={{width: "100%"}} form={form} onFinish={handleSubmitMilestone}>
                    <InputFormField
                        form={{
                            name: "name",
                            label: t("Name of the Financial Framework"),
                        }}
                        fullWidth={true}
                    />
                    {
                        getShowEstimatedValueForm() &&
                        <InputFormField
                            form={{
                                name: "estimated_value",
                                label: t("Estimated value"),
                                rules: [IS_REQUIRED],
                                help: t("This value cannot be changed after the baseline is finalized")
                            }}
                            fullWidth={true}
                            type="number"
                            disabled={getFormDisable()}
                        />
                    }

                    <DatePickerFormField
                        form={{
                            name: "end_date",
                            label: t("Milestone end date"),
                            rules: [IS_REQUIRED],
                            help: t("This value cannot be changed after the baseline is finalized")

                        }}
                        disabled={getFormDisable()}
                    />
                    <RequestSelectFormField
                        form={{
                            name: "status_reports",
                            label: t("Checkpoint when this value was updated"),
                            help: t("Date this milestone will be updated")
                        }}
                        url={`project_management/project/${project.id}/get_status_report_list`}
                        formatLabel={(item)=>`${item.order} (${DateFormatUser(item.date, _user)})`}
                        mode="multiple"
                    />
                    <InputFormField
                        form={{
                            name: "forecast_value",
                            label: t("Forecast value"),
                            help: t("The forecast is used to generate a future prediction of the expenditure based on the value updated at the checkpoint and the estimated value for this amount. This value can be calculated automatically based on the regression model"),
                    }}
                        type="number"
                        disabled={forecastMode === "automatic"}
                        // placeholder={forecastMode === "automatic" && "valor gerado automaticamente"}
                    />
                    <TextAreaFormField
                        form={{
                            name: "description",
                            label: t("Description")
                        }}
                        showCount={true}
                        maxLength={100}
                    />
                </Form>
            </Flex>
        </Drawer>
    );
}

