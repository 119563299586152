import { Col, Flex, Row, Typography } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DangerousHtmlPreviewer from "../../../../components/data_display/DangerousHtmlPreviewer";
import RankingPlacements from "../../../../components/data_display/RankingPlacements";
import WideSpace from "../../../../components/layout/WideSpace";
import { useThemeContext } from "../../../../context/ThemeContext";
import useApplication from "../../../../hooks/useApplication";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import useQuery from "../../../../hooks/useQuery";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import { RANKING_STATUS } from "../../../../utils/Constants";
import ProgramBasicInfo from "../components/ProgramBasicInfo";
import ProgramDescriptionCard from "../components/ProgramDescriptionCard";
import ProgramSuggestButton from "../components/ProgramSuggestButton";
import { useProgramDetailContext } from "../context/ProgramDetailContext";

const {Text} = Typography


const StyledImage = styled.img`
    object-fit: cover;
    border-radius: 6px;
    width: 100%;

    @media (max-width: 480px) {
        height: 45px;
    }

    @media (max-width: 768px){
        height: 89px;
    }

    @media (min-width: 1024px) {
        height: 119px;
    }

    @media (min-width: 1440px) {
        height: 190px;
    }
`;

function DescriptionProgramTab({}) {
    const {t} = useTranslation();
    const screens = useBreakpoint();
    const {program: challenge, getProgramInfo, isPublic} = useProgramDetailContext();
    const program = getProgramInfo();
    const {themeConfig: {client}} = useThemeContext()
    const {isDesktop} = useSystemBreakpoint();
    const {handleApplyChallenge, loadingRequest} = useApplication();
    const query = useQuery();

    // Se vier da pagina publica, iniciar preenchimento do formulario automaticamente.
    useEffect(() => {
        console.log(query.get("challenge"))
        const extraParams = query.get("challenge") && {challenge: query.get("challenge")};
        challenge && query.get("apply") === 'yes' && handleApplyChallenge(challenge, extraParams)
        console.log(extraParams)
    }, [challenge]);

    const {
        data
        // isLoading
    } = useDefaultGetRequest({
        url: `/ranking/program/${program?.id}/get_ranking_object_related/`,
        params: {status: RANKING_STATUS.OPENED},
        makeRequest: !!program?.id && client?.gamification_actived && client?.show_ranking_in_home && !isPublic,
        dataDefault: []
    })

    const rankings = data?.results;
    const hasToShowRanking = (screens.xl || screens.xxl) && client?.gamification_actived && client?.show_ranking_in_home && rankings?.length > 0

    return (
        <WideSpace direction="vertical" size="large">
            <StyledImage src={program?.banner} />

            <Row justify="space-between" gutter={[16, 16]} align="middle">
                <Col span={isDesktop ? 14 : 24}>
                    <ProgramBasicInfo program={program}/>
                </Col>
                <Col span={isDesktop ? 10 : 24}>
                    <ProgramSuggestButton program={program} challenge={challenge} onClick={() => handleApplyChallenge(challenge)}/>
                </Col>
            </Row>

            <Row justify="space-between" gutter={[16, 16]} align="middle">
                <Col span={hasToShowRanking ? 14 : 24}>
                    <Flex gap={16} vertical={true}>
                        <ProgramDescriptionCard
                            title={t("About the program")}
                            description={<DangerousHtmlPreviewer content={program?.description}/>}
                        />

                        <ProgramDescriptionCard
                            title={t("Type of registrations accepted")}
                            description={<Text>{program?.team_types_display.map(type => t(type)).join(", ")}</Text>}
                        />
                    </Flex>
                </Col>
                {hasToShowRanking &&
                    <Col span={10} style={{justifyItems: "self-end"}}>
                        <RankingPlacements
                            open_at={rankings?.[0]?.open_at}
                            close_at={rankings?.[0]?.close_at}
                            endless={rankings?.[0]?.endless}
                            id={rankings?.[0]?.id}
                            title={t('Program ranking')}/>
                    </Col>
                }
            </Row>
        </WideSpace>
    );
}


export default DescriptionProgramTab;
