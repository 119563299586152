import React from 'react';
import Table from "../../../../global/components/data_display/Table";
import {useTranslation} from "react-i18next";
import SolvTag from "../../../../global/components/tag/SolvTag";
import {dust_red_1, page_background, polar_green_1} from "../../../../global/utils/Colors";
import useTable from "../../../../global/hooks/useTable";
import {useProjectManagementContext} from "../../pages/ProjectManagement/context/ProjectManagementContext";
import Loading from "../../../../global/components/feedback/Loading";
import useEventListener from "../../../../global/hooks/useEventListener";
import {DateFormatUser} from "../../../../global/utils/dates";
import {useUserContext} from "../../../../global/context/UserContext";
import { formatNumber } from '../../utils/utils';


function MilestoneStatusTag({status}) {
    const {t} = useTranslation();

    const colorByStatus = {
        value_exceeded: dust_red_1,
        realized: polar_green_1,
        not_realized: page_background,
    }

    return (
        <SolvTag maxWidth={150} color={colorByStatus[status]}>
            {t(status)}
        </SolvTag>
    )
}

export default function InvestmentFinancialPlanningTable({form, setDrawerOpen}) {

    const {t} = useTranslation();
    const {project} = useProjectManagementContext()
    const {_user} = useUserContext();

    const {
        tableData, isLoading, handleRequestTable, tableParams, handleTableChange
    } = useTable({
        url: `/project_management/forecast_milestone/list_by_project/${project.id}`,
        config: {version: "v1"},
        dependenceRequest: [project]
    })

    const columns = [
        {
            title: t("Reference date"),
            dataIndex: "end_date",
            key: "end_date",
            sorter:"end_date",
            render: (date) => DateFormatUser(date, _user)
        },
        {
            title: t("Milestone Status"),
            dataIndex: "status",
            key: "status",
            sorter:"status",
            render: (status) => <MilestoneStatusTag status={status}/>
        },
        {
            title: t("Estimated Value (Baseline)"),
            dataIndex: "estimated_value",
            key: "estimated_value",
            sorter:"estimated_value",
            render: (value) => value !== null ? `R$ ${formatNumber(value)}` : "-"
        },
        {
            title: t("Related checkpoint"),
            dataIndex: "status_report_numbers",
            key: "status_report_numbers",
            render: (value) => value.join(', ')
        },
        {
            title: t("Investment Made"),
            dataIndex: "realized_investment",
            key: "investment_made",
            render: (value) => value !== null ? `R$ ${formatNumber(value)}` : "-"
        },
        {
            title: t("Forecast"),
            dataIndex: "forecast_value",
            key: "forecast_value",
            sorter:"forecast_value",
            render: (value) => value !== null ? `R$ ${formatNumber(value)}` : "-"
        },
    ]

    useEventListener("refresh-investment-financial-planning-table", handleRequestTable);

    return (
        <Table
            loading={isLoading}
            columns={columns}
            dataSource={tableData}
            tableParams={tableParams}
            onChange={handleTableChange}
            onRowClick={ async (data) => {
                await form.setFieldsValue(data)
                setDrawerOpen("FinancialMilestoneFormDrawer")
            }}
        />
    );
}
