import {PlusOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FlexContainer} from "../../components/authentication/StyledComponents";
import Button from "../../components/data_entry/Button";
import {CardContainer} from "../../components/misc/CardContainer";
import ApplicationDrawer
    from "../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import GrowRadioButton from "../../components/responsive/GrowRadioButton";
import {usePermissionContext} from "../../context/PermissionContext";
import {useUserContext} from "../../context/UserContext";
import useChallenge from "../../hooks/useChallenge";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import SolutionsTable from "./components/SolutionsTable";
import Search from "../../components/data_entry/Search";
import FilterSolutionsButton from "./components/FilterSolutionsButton";
import SolutionsCard from "./components/SolutionsCard";

const DEFAULT_BODY_TABLE = {
    ALL_CHALLENGES: '',
    ACTIVE: true
}
const NAMES_FILTES = {
    MY_CHALLENGE: 'team__members__user_id',
    IS_ACTIVE: 'is_active'
}


export default function ListSolutions() {
    const {t} = useTranslation();
    const {_user} = useUserContext();
    const [solutionDrawerOpen, setSolutionDrawerOpen] = useState(false)
    const {isDesktop} = useSystemBreakpoint()
    const [solutionId, setSolutionId] = useState();
    const [filters, setFilters] = useState({'preview': 'card'});

    const {handleCreateSolutionAndRedirect} = useChallenge();
    const [search, setSearch] = useState("")

    const {
        canSuggestSolution,
    } = usePermissionContext();

    const [bodyRequest, setBodyRequest] = useState({
        [NAMES_FILTES.IS_ACTIVE]: DEFAULT_BODY_TABLE.ACTIVE,
        [NAMES_FILTES.MY_CHALLENGE]: _user.id
    })


    function handleRowClick(record) {
        setSolutionId(record.id);
        setSolutionDrawerOpen(true);
    }


    function handleFilterMyChallenges(value) {

        if (value) {
            setBodyRequest({
                ...bodyRequest,
                [NAMES_FILTES.MY_CHALLENGE]: _user.id
            })
        } else {
            setBodyRequest({[NAMES_FILTES.IS_ACTIVE]: bodyRequest.is_active})
        }
    }


    useEffect(() => {
        if (filters) {
            const newFilters = {...filters}
            delete newFilters['preview']
            setBodyRequest({
                ...bodyRequest,
                ...newFilters
            })
        }
    }, [filters]);

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24}>


                    <CardContainer>

                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={9}>
                                <GrowRadioButton
                                    onChange={handleFilterMyChallenges}
                                    defaultValue={_user.id}
                                    block={!isDesktop}
                                    options={[
                                        {
                                            value: _user.id,
                                            label: t('My Solutions')
                                        },
                                        {
                                            value: '',
                                            label: t('All Solutions')
                                        },]}
                                />
                            </Col>
                            <Col xs={24} xl={15}>
                                <FlexContainer gap={16}
                                               justify={'end'} wrap={'wrap'}>
                                    <Search onSearch={setSearch} allowClear
                                            width={!isDesktop ? '100%' : "200px"}/>
                                    <FilterSolutionsButton filters={filters}
                                                           setFilters={setFilters}/>

                                    {canSuggestSolution && <Button
                                        icon={<PlusOutlined/>}
                                        onClick={handleCreateSolutionAndRedirect}>
                                        {t("Suggest Solution")}
                                    </Button>}
                                </FlexContainer>
                            </Col>


                        </Row>


                    </CardContainer>
                </Col>
                <Col xs={24}>

                    {filters.preview === 'table' ?
                        <SolutionsTable filters={filters} search={search}
                                        bodyRequest={bodyRequest}
                                        onRowClick={handleRowClick}/> :
                        <SolutionsCard filters={filters} search={search}
                                       bodyRequest={bodyRequest}
                                       onCardClick={handleRowClick}/>}
                </Col>
            </Row>
            <ApplicationDrawer solutionId={solutionId}
                               open={solutionDrawerOpen}
                               setOpen={setSolutionDrawerOpen}
                // postUpdate={resetTable}
            />
        </>


    )
}
