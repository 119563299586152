import WideSpace from "../../../../components/layout/WideSpace";
import {BulbOutlined, SettingOutlined} from "@ant-design/icons";
import {toTitleCase} from "../../../../../modules/project/utils/utils";
import styled from "styled-components";
import Button from "../../../../components/data_entry/Button";
import useApplication from "../../../../hooks/useApplication";
import {useTranslation} from "react-i18next";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import {Link} from "react-router-dom";
import React from "react";
import {Flex} from "antd";


const StyledLargeButton = styled(Button)`
    width: 100%;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
`

const StyledSmallButton = styled(Button)`
    width: 55px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
`


function ProgramSuggestButton({program, challenge, onClick}) {

    const {t} = useTranslation();
    const {handleApplyChallenge, loadingRequest} = useApplication();
    const {isDesktop} = useSystemBreakpoint();

    return (
        <Flex justify="end" gap={8}>
            <StyledLargeButton
                style={{maxWidth: isDesktop && "315px"}}
                loading={loadingRequest}
                onClick={onClick}
            >
                <WideSpace size="large">
                    <BulbOutlined/>
                    {`${t("Suggest")} ${t(toTitleCase(program?.application_name_choice))}`}
                </WideSpace>
            </StyledLargeButton>
            {challenge?.has_program_manager_permission &&
                <Link to={`/programs/${program?.id}/phases`}>
                    <StyledSmallButton type="default">
                        <SettingOutlined/>
                    </StyledSmallButton>
                </Link>
            }
        </Flex>
    );
}

export default ProgramSuggestButton;