import React from "react";
import styled from "styled-components";
import Logo from "../../../../../assets/img/solv/logo_6.svg";
import PoweredByNeo from "../../../../../assets/img/powered_by_neo_white.svg";
import { neutral_2 } from "../../../../utils/Colors";
import Flex from "../../../../components/responsive/Flex";
import RemixIcon from "../../../../components/data_display/RemixIcon";

const StyledFooter = styled.div`
    background-color: ${neutral_2};
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
`;

const DarkSection = styled.div`
    background: #4B4B4B;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const FooterImage = styled.img`
    width: 120px;
    scale: 1.5;
`;

const MainImage = styled.img`
    width: 116px;
    display: block;
`;

const StyledIcon = {
    color: "#4B4B4B",
    fontSize: 24,
}

const socialLinks = [
    { icon: "ri-facebook-fill", url: "https://www.facebook.com/neoventures.global/" },
    { icon: "ri-instagram-line", url: "https://www.instagram.com/neoventures.global/" },
    { icon: "ri-linkedin-box-fill", url: "https://www.linkedin.com/company/neoventuresglobal/" },
    { icon: "ri-mail-line", url: "mailto:" }
];

const PublicFooter = () => {
    return (
        <StyledFooter>

            <Flex vertical={true} align="center" justify="center" gap={16}>
                <a href="https://solv.network/" target="_blank" rel="noopener noreferrer">
                    <MainImage src={Logo} alt="Logo" />
                </a>

                <Flex align="center" justify="space-evenly" gap={4}>
                    {socialLinks.map((social, index) => (
                        <a key={index} href={social.url} target="_blank" rel="noopener noreferrer">
                            <RemixIcon remixIconName={social.icon} style={StyledIcon} />
                        </a>
                    ))}
                </Flex>
            </Flex>

            <DarkSection>
                <a href="https://neoventures.global/" target="_blank" rel="noopener noreferrer">
                    <FooterImage src={PoweredByNeo} alt="Footer Image" />
                </a>
            </DarkSection>
        </StyledFooter>
    );
};

export default PublicFooter;