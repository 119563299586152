import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FilterButton from "../../../components/button/FilterButton";
import {Drawer, Form, Form as FormAnt} from "antd";
import DefaultButtonDrawerFilter
    from "../../../components/layout/DefaultButtonDrawerFilter";
import SimpleCollapse from "../../../components/misc/SimpleCollapse";
import FilterStatusApplication
    from "../../../components/other/FilterStatusApplication";
import RadioFormField
    from "../../../components/data_entry/form/RadioFormField";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../utils/Utils";

const FORM_NAMES = {
    STATUS: 'status__in',
    PREVIEW: 'preview'
}


const DEFAULTS = {
    [FORM_NAMES.PREVIEW]: 'card',
}

export default function FilterSolutionsButton({
                                                  filters,
                                                  setFilters,
                                                  FORM_NAMES_FILTERS
                                              }) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()


    function onClose() {
        setOpen(false)
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()


        return data
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()
            setFilters(data)
            ToastNotification(t(ConstantsMessage.SUCCESS))
            onClose()

        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {

            setIsLoading(false);
        }
    };
    return (
        <>
            <FilterButton hasText onClick={() => setOpen(true)}/>

            <Drawer title={t('Filters')} width={520} open={open}
                    onClose={onClose}
                    footer={<DefaultButtonDrawerFilter defaults={{}}
                                                       isLoading={isLoading}
                                                       form={form}/>}>

                <Form form={form} disabled={isLoading} initialValues={DEFAULTS}
                      onFinish={handleSubmit}>
                    <SimpleCollapse title={t('Preview')}>
                        <RadioFormField form={{name: FORM_NAMES.PREVIEW}}
                                        options={[
                                            {value: 'card', label: t('Cards')},
                                            {value: 'table',label: t('Table')},
                                        ]}/>
                    </SimpleCollapse>
                    <FilterStatusApplication form={{
                        name: FORM_NAMES.STATUS
                    }}/>


                </Form>
            </Drawer>

        </>


    )
}
