import React from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../../../context/ThemeContext";
import styled from "styled-components";
import { Space, Typography } from "antd";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";

const { Title } = Typography;

const HeaderContainer = styled.div`
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden; 
    min-height: 120px;

    @media (max-width: 480px) {
        height: 120px; 
    }

    @media (max-width: 768px) {
        height: 150px;
    }

    @media (min-width: 1024px) {
        height: 200px; 
    }

    @media (min-width: 1440px) {
        height: 350px; 
    }
`;

const ImageBG = styled.img`
    width: 100%;
    height: 200px;
    background-position: 40% 40%;
    object-fit: cover;
    position: absolute;
    transform: scale(100%);

    @media (max-width: 480px) {
        height: 120px;
    }

    @media (max-width: 768px) {
        height: 150px;
    }

    @media (min-width: 1024px) {
        height: 200px;
    }

    @media (min-width: 1440px) {
        height: 350px;
    }
`;

const StyledInputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: -24px;
    gap: 16px;
    padding: 32px 24px;
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
    backdrop-filter: ${({ $blur }) => $blur && "blur(3px)"};
    top: 0;

    .ant-input-search {
        max-width: 500px;
    }

    @media (max-width: 480px) {
        padding: 8px 4px;
        height: 120px;
    }

    @media (max-width: 768px) {
        padding: 24px 16px;
        height: 150px;
    }

    @media (min-width: 1024px) {
        padding: 32px 24px;
        height: 200px;
    }

    @media (min-width: 1440px) {
        padding: 48px 32px;
        height: 350px;
    }
`;

export default function SectionWithInput({ title, children = null, icon, blur=true, imgSrc }) {
    const { t } = useTranslation();
    const { themeConfig } = useThemeContext();
    const { isPhone } = useSystemBreakpoint();
    const src = imgSrc || themeConfig.login_img_cover

    return (
        <HeaderContainer isPhone={isPhone}>
            <ImageBG src={src} />
            <StyledInputContainer $blur={blur}>
                    <Space size="middle">
                        <Title
                            level={isPhone ? 3 : 2}
                            style={{
                                color: "white",
                                marginBottom: 0,
                            }}
                        >
                            {title}
                        </Title>
                        {icon}
                    </Space>
                    {children}
            </StyledInputContainer>
        </HeaderContainer>
    );
}
