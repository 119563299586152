import {Input, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import BannerUploader from "../../../../../../global/components/data_entry/BannerUploader";
import FilesTable from "../../../../../../global/components/data_entry/FilesTable";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import RequestSelect from "../../../../../../global/components/data_entry/RequestSelect";
import WideSpace from "../../../../../../global/components/layout/WideSpace";
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import {useRules} from "../../../../../../global/hooks/useRules";
import CkEditor, {SIMPLE_EDITOR_OPTIONS} from "../../../../../../modules/project/components/CKEditor";

const { Text } = Typography;

export default function DescriptionSettingsCollapse({ program, FORM_NAMES }) {
    const { t } = useTranslation();
    const rules = useRules();

    return (
        <TransparentCollapse header={t("Description")}>
            <WideSpace  direction="vertical" size="middle">
                    <CollapseFormItem
                        input={<BannerUploader />}
                        name={FORM_NAMES.BANNER}
                        label={
                            <span>
                                <Text>Banner </Text>
                                <Text type="secondary">Tamanho sugerido: 1630x190</Text>
                            </span>
                        }
                        rules={[rules.IS_REQUIRED]}
                        extraFormItemProps={{ valuePropName: "img" }}
                        wideInput
                    />
                    <CollapseFormItem
                        input={<Input data-cy="program-name-input"/>}
                        name={FORM_NAMES.NAME}
                        help={t("The name helps identify the program.")}
                        label={t("Program Name")}
                        rules={[rules.IS_REQUIRED]}
                    />
                    <CollapseFormItem
                        input={
                            <RequestSelect 
                                url={'/sectors'} 
                                mode="multiple" 
                                labelField="name" 
                                valueField="id"
                                data-cy="strategic-alignment-select"
                            />
                        }
                        name={FORM_NAMES.STRATEGIC_ALIGMENT}
                        help={t("Determines the program's business sectors.")}
                        label={t("Strategic Alignment")}
                        rules={[rules.IS_REQUIRED]}
                    />
                    <CollapseFormItem 
                        input={<CkEditor flex height="200px" {...SIMPLE_EDITOR_OPTIONS} />}
                        name={FORM_NAMES.DESCRIPTION} 
                        label={<>{t("Description")}</>}
                        wideInput
                        extraFormItemProps={{ initialValue: program?.description }}
                        data-cy="program-description-input"
                    />
                    {program && <FilesTable hasPermission={true} fileType={'program'} id={program.id} cardStyle={{borderRadius: 8}}/>}
            </WideSpace>      
        </TransparentCollapse>
    );
}
