import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Flex, Row, Space, Typography} from "antd";
import CircularImage from "./CircularImage";
import styled from 'styled-components';
import {DatetimeFormatByTimezone} from "../../utils/dates";
import SolvTag from "../tag/SolvTag";
import {FlexContainer} from "../authentication/StyledComponents";
import UserContext, {useUserContext} from "../../context/UserContext";
import {useParams} from "react-router-dom";
import {getOrDefault} from "../../utils/Utils";
import RobotImage from "../../../assets/img/robot.webp";
import UserAvatar from "../misc/UserAvatar";
import {blue_1} from "../../utils/Colors";
import {useProgramDetailContext} from '../../pages/programs/Program/context/ProgramDetailContext';
import {useApplicationDrawerContext} from "../other/application/ApplicationDrawer/context/ApplicationDrawerContext";
import {HeartOutlined} from "@ant-design/icons";
import api from "../../services/api";
import LikeIconContainer from "../../pages/home/Feed/components/LikeIconContainer";

const {Text, Title, Paragraph} = Typography;

const Containter = styled.div`
    background: ${({itsMe}) => itsMe ? blue_1 : 'white'};
    padding: 16px;

    margin-left: ${({itsMe}) => itsMe ? '16px' : '0px'};
    margin-right: ${({itsMe}) => itsMe ? '0px' : '16px'};
`
const Name = styled(Title)`
    font-size: 14px !important;
    margin-bottom: 0 !important;
`

const SeeMoreButton = styled.span`
    color: #00000073;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
`

const AMOUNT_CARACTERES = 1500


function MessageContainer({itsMe, program, comment_config, owner, created_at, created_at_time_delta, handleSeeMore, isCommentLarge, setSeeMore}) {
    const {t} = useTranslation()
    return (
        <Containter itsMe={itsMe}>
            <Row>
                <Col flex={'auto'}>
                    <FlexContainer
                        wrap={'wrap'}
                        gap={8} justify={itsMe ? 'end' : 'start'}
                        direction={itsMe ? 'row-reverse' : 'row'}>
                        <ChatUserAvatar program={program} comment_config={comment_config} owner={owner}/>
                        <Text type={'secondary'}>{created_at_time_delta} {t("ago")} </Text>
                    </FlexContainer>

                </Col>
                <Col xs={24}>
                    <Paragraph style={{marginTop: 8}}>
                        {handleSeeMore()}
                    </Paragraph>
                </Col>
                {isCommentLarge() && <Col xs={24}>
                    <SeeMoreButton
                        onClick={() => setSeeMore(true)}>{t('See more')}</SeeMoreButton>
                </Col>}
            </Row>
        </Containter>
    )
}

function CommentContainer({id, owner, created_at, created_at_time_delta, permissions, handleSeeMore, isCommentLarge, setSeeMore, topic_reactions}){
    const {t} = useTranslation()
    let {topic} = useApplicationDrawerContext()
    const teamMembersId = topic?.object?.team_member_ids

    return(
        <Flex align="flex-start" gap={8} style={{paddingTop:16}}>
            <UserAvatar size={"middle"}
                        data={{
                            picture: getOrDefault(owner, 'picture', RobotImage),
                            name: getOrDefault(owner, 'first_name', t('System Message'))
                        }}
            />
            <Flex style={{width:"100%"}}>
                <Flex vertical={true} flex={1}>
                    <Flex justify="space-between">
                        <Flex gap={4} align="center">
                            <Text style={{
                                fontSize: 12,
                                fontWeight: 500
                            }}>
                                {getOrDefault(owner, 'first_name', t('System Message'))}
                            </Text>
                            <Text type="secondary" style={{fontSize: 10}}>{created_at_time_delta} {t("ago")}</Text>

                        </Flex>

                        <LikeIconContainer object="comments" object_id={id} topicReactions={topic_reactions}/>

                    </Flex>
                    <Flex gap={2}>
                        {permissions?.map((permission, index) => (
                            <Flex gap={2}>
                                <Text type="secondary" style={{fontSize: 10}}>
                                    {`${t(permission)}`}
                                </Text>
                                {index < (permissions.length - 1) && <Text style={{fontSize: 10}} type="secondary">|</Text>}
                            </Flex>
                        ))}
                        {teamMembersId?.includes(owner?.id) &&
                            <Flex gap={2}>
                                {(permissions.length > 0) && <Text style={{fontSize: 10}} type="secondary">|</Text>}
                                <Text strong={true} style={{fontSize: 10}}>{t("Author")}</Text>
                            </Flex>
                        }
                    </Flex>
                    <Flex>
                        {handleSeeMore()}
                    </Flex>
                    {isCommentLarge() &&
                        <Flex justify="end">
                            <SeeMoreButton
                                onClick={() => setSeeMore(true)}>{t('See more')}
                            </SeeMoreButton>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}

function ChatUserAvatar({comment_config, owner, program}) {
    const {t} = useTranslation();
    /*TODO E QUANDO NAO TIVER PROGRAM? EXISTE ESSA POSSIBILIDATE? no modal da application nao veio informacao de program*/
    const programData = {name: program?.name, picture: program?.client_profile}
    if (comment_config === "manager") {
        return <>
            <UserAvatar size={24} data={programData}/>
            <Name level={5}>{t("Program")}: {programData.name}</Name>
        </>
    }
    return <>
        <UserAvatar
            size={24}
            data={{
                picture: getOrDefault(owner, 'picture', RobotImage),
                name: getOrDefault(owner, 'first_name', t('System Message'))
            }}
        />
        <Name
            level={5}>{getOrDefault(owner, 'first_name', t('System Message'))} {getOrDefault(owner, 'last_name')}</Name>
    </>
}

export default function ChatComment({
                                        id,
                                        owner,
                                        comment,
                                        message,
                                        created_at,
                                        created_at_time_delta,
                                        permissions,
                                        comment_config,
                                        topic_reactions,
                                    }) {
    const [seeMore, setSeeMore] = useState(false);
    const [itsMe, setItsMe] = useState(false);
    const {_user} = useUserContext();
    const {program} = useProgramDetailContext();
    const {commentMode} = useApplicationDrawerContext()

    useEffect(() => {
        setItsMe(_user.id === owner?.id)
    }, [_user])

    function handleSeeMore() {
        if (isCommentLarge()) {
            return `${message.substring(0, AMOUNT_CARACTERES)}...`
        }
        return message
    }

    function isCommentLarge() {
        return message.length > AMOUNT_CARACTERES && !seeMore
    }

    return (
        commentMode ?
            <CommentContainer
                id={id}
                itsMe={itsMe}
                program={program}
                comment_config={comment_config}
                owner={owner}
                created_at={created_at}
                created_at_time_delta={created_at_time_delta}
                permissions={permissions}
                handleSeeMore={handleSeeMore}
                isCommentLarge={isCommentLarge}
                setSeeMore={setSeeMore}
                topic_reactions={topic_reactions}
            />
            :
            <MessageContainer
                itsMe={itsMe}
                program={program}
                comment_config={comment_config}
                owner={owner}
                created_at={created_at}
                created_at_time_delta={created_at_time_delta}
                handleSeeMore={handleSeeMore}
                isCommentLarge={isCommentLarge}
                setSeeMore={setSeeMore}
            />
    )
}