import {Input, Skeleton, Form, Alert} from "antd";
import {useTranslation} from "react-i18next";
import CollapseFormItem from "../../../global/components/data_entry/CollapseFormItem";
import DateRangePicker from "../../../global/components/data_entry/DateRangePicker";
import Select from "../../../global/components/data_entry/Select";
import WideSpace from "../../../global/components/layout/WideSpace";
import TransparentCollapse from "../../../global/components/misc/TransparentCollapse";
import useOptionsTranslation from "../../../global/hooks/useOptionsTranslation";
import {useRules} from "../../../global/hooks/useRules";
import {
    CHOICES_FORECAST_TYPES,
    CHOICES_PROJECT_CHECKPOINTS,
    CHOICES_PROJECT_STATUS, getChoicesForecastFrequency,
    PROJECT_TAG
} from "../../../global/utils/Choices";
import {useProjectManagementContext} from "../pages/ProjectManagement/context/ProjectManagementContext";
import AttachingTags from "../../../global/components/tag/AttachingTags";
import React, {useEffect, useState} from "react";


function ProjectForm({project, form}) {
    const {t} = useTranslation();
    const {translationOptions} = useOptionsTranslation();
    const {clientPhases} = useProjectManagementContext();
    const isLoadingData = !project && project !== null;
    const rules = useRules();
    const dates = [project?.date_start, project?.date_end];
    const phaseOptions = clientPhases?.map(phase => ({
        label: phase.label,
        value: phase.id
    }))

    const getCurrentPhase = () => {
        const currentProjectPhase = project && project["current_phase"];
        return currentProjectPhase?.phase?.id;
    }

    const currentPhase = getCurrentPhase();
    const handlePhaseChange = (value) => {
        form.setFieldsValue({phase: value});
    };

    const checkpointSetting = Form.useWatch("checkpoint_setting", form)

    useEffect(() => {
        form.setFieldValue('forecast_frequency', checkpointSetting);
    }, [checkpointSetting])

    return (
        <Skeleton loading={isLoadingData}>
                <TransparentCollapse header={t("General")}>
                    <WideSpace direction="vertical" size="middle">
                        <CollapseFormItem
                            name="name"
                            label={t("Name of the initiative")}
                            input={<Input placeholder={t("example")} data-cy="name-input"/>}
                            rules={[rules.IS_REQUIRED]}
                        />
                        <CollapseFormItem
                            name="date_range"
                            label={t("Duration of the initiative")}
                            help={t("Please provide estimated start and end dates for the initiative. This information will be used to create the first task in the Gantt chart, which is located on the planning screen")}
                            input={<DateRangePicker inputWidth={145} data-cy={"date-range-input"}/>}
                            rules={[rules.IS_REQUIRED]}
                            extraFormItemProps={{initialValue: dates}}
                        />
                        <CollapseFormItem
                            name="status_proj"
                            label={t("Status of the initiative")}
                            help={t("The status determines whether or not the initiative is displayed in the comparison charts. Only the ‘active’ status appears in the graphs")}
                            input={<Select options={translationOptions(CHOICES_PROJECT_STATUS)}
                                           data-cy="project-status-select"
                                           defaultValue={"active"}
                            />}
                        />

                        {project &&
                            <CollapseFormItem
                                name="phase"
                                label={t("Initiative phase")}
                                help={t("It serves to determine which period or level of maturity this initiative is in")}
                                input={<Select options={translationOptions(phaseOptions)} onChange={handlePhaseChange}
                                               data-cy="project-phase-select"
                                />}
                                extraFormItemProps={{initialValue: currentPhase}}
                            />
                        }
                        {project?.id && <AttachingTags target={PROJECT_TAG}
                                                       id={project?.id}/>}
                    </WideSpace>
                </TransparentCollapse>

                <TransparentCollapse header={t("Planning")}>
                    <WideSpace direction="vertical" size="middle" style={{marginBottom: 50}}>
                        <Alert message={t("Planning settings are to ensure that the initial creation of schedules and setting of attendance reminders.")} type="warning"/>
                        <CollapseFormItem
                            name="checkpoint_setting"
                            label={t("Checkpoint Frequency")}
                            help={t("This configuration creates an alert to remind you of the meeting to update the indicators for this initiative through the checkpoint")}
                            input={<Select options={translationOptions(CHOICES_PROJECT_CHECKPOINTS)}
                                           data-cy="checkpoint-frequency-select"
                                           defaultValue={30}
                            />}
                            renderContent={() =>
                                <CollapseFormItem
                                    name="forecast_frequency"
                                    label={t("Financial milestone frequency")}
                                    help={`${t("The frequency of financial milestones takes into account the number of updates that will be made to the financial planning. The frequency of financial milestones must always be equal to or greater than that of the checkpoints")}.`}
                                    input={<Select
                                        options={translationOptions(getChoicesForecastFrequency(checkpointSetting))}
                                        data-cy="financial-frequency-select"
                                        defaultValue={30}
                                    />}
                                />
                            }
                            initialOpen={true}
                        />
                        <CollapseFormItem
                            name="forecast_mode"
                            label={t("Forecast Type")}
                            help={`${t("Forecast is a financial projection based on the financial baseline and the updates to financial milestones")}.`}
                            input={<Select options={translationOptions(CHOICES_FORECAST_TYPES)}
                                           data-cy="forecast-type-select"
                                           defaultValue={"automatic"}
                            />}
                        />
                    </WideSpace>
                </TransparentCollapse>

        </Skeleton>
    );
}

export default ProjectForm;