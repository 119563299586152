import React, { useEffect, useRef, useState} from 'react';
import {
    ReactComponent as LogoMicrosfot
} from "../../../assets/newicons/logo_microsoft.svg";
import {
    ReactComponent as GoogleIcon
} from "../../../assets/Icon/google.svg";
import {ToastNotification} from '../../components/feedback/ToastNotification';
import GoogleLogin from '../../components/authentication/GoogleLogin';
import {useTranslation} from "react-i18next";
import MicrosoftLogin from "react-microsoft-login";
import styled from 'styled-components'
import {LinkOutlined} from "@ant-design/icons";
import {
    Button,
    Col, Divider,
    Row, Form as FormAntd
} from "antd";
import InputFormField from "../../components/data_entry/form/InputFormField";
import {NavLink, useHistory} from "react-router-dom";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import api from "../../services/api";
import {useUserContext} from "../../context/UserContext";
import {
    hasAcceptedCookies,

} from "../../utils/Auth";
import {HOST_ENTREPRENEUR, MICROSOFT_APP_CLIENT_ID} from "../../../settings";

import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import Form from "../../components/data_entry/form/Form";
import {AuthButton} from "../../components/authentication/StyledComponents";
import PasswordFormField from "../../components/data_entry/form/PasswordFormField";
import useQuery from '../../hooks/useQuery';


const SSOStyledButton = styled(Button)`
  /* position: relative;

  background: white !important;
  color: rgba(0, 0, 0, 0.85) !important; */

  & > :first-child, &:active > *:first-child, &:focus > *:first-child {
    position: absolute;

    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
`


export default function Login() {
    const {t} = useTranslation();

    const [form] = FormAntd.useForm();

    const googleButton = useRef()
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [registerConfirmEmail, setRegisterConfirmEmail] = useState(null)
    const {setRefresh} = useUserContext();
    const query = useQuery();

    const [blockedGoogle, setBlockedGoogle] = useState(false)

    const redirectToNextPage = () => {
        if (query.has("next")) {
            const urlParams = new URLSearchParams(window.location.search);
            const next = urlParams.get('next');
            urlParams.delete('next');
            return history.push(`${next}?${urlParams}`);
        }
        let redirect = window.location.host === HOST_ENTREPRENEUR ? "/home" : "/home/innovation_panel"
        return history.push(redirect)
    }

    const MicrosoftHandleLogin = async (err, data, msal) => {
        if (!err && !isLoading && data) {
            try {
                setIsLoading(true)
                const response = await api.post('auth/microsoft/', data);

                setRefresh(true)
                redirectToNextPage();
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                localStorage.clear();
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
        }
    };

    const GoogleSuccessAuthentication = async (response) => {
        if (!blockedGoogle) {
            const data = {
                // 'email': response['profileObj']['email'],
                'token': response['credential'],
            };
            try {
                setIsLoading(true)
                const response = await api.post('auth/google/', data);

                setRefresh(true)
                redirectToNextPage();
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
                setBlockedGoogle(true)
            } finally {
                setIsLoading(false);
            }
        }

    };

    const GoogleErrorAuthentication = response => {
        setBlockedGoogle(true)
        DEBUG && console.debug(response)
    };


    const doLogin = () => {
        if (!hasAcceptedCookies()) {
            ToastNotification(t("Please accept cookies"), 'warning')
            return
        }
        handleRegisterConfirmEmail()

    }

    const handleRegisterConfirmEmail = async () => {

        try {
            setIsLoading(true)
            let username = form.getFieldValue('username')
            const response = await api.get(`auth/register_confirm_email_check/${username}`);
            setRegisterConfirmEmail(response.data.is_confirmed_email)
            let is_confirmed_email = response.data.is_confirmed_email
            if (!is_confirmed_email) {
                ToastNotification(t('Email not confirmed'), 'error')
                history.push('/notice_confirm_email/' + response.data.email)
            }

            if (is_confirmed_email) {
                await handleSubmit()
            }

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }


    };
    //
    const handleSubmit = async () => {
        // cleanInputsError()

        try {
            setIsLoading(true)
            const data = await form.getFieldsValue()
            const response = await api.post('auth/token/', data);

            setRefresh(true)
            redirectToNextPage()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <LayoutLogin>

                <Form form={form}
                          requiredMark={false}
                          onFinish={doLogin}
                          style={{marginTop: 16}}>
                    <Row>
                        <Col xs={24}>
                            <InputFormField form={{
                                label: t('Email'),
                                name: 'username',

                                rules: [{
                                    required: true,
                                    message: t('E-mail is required')
                                }, {
                                    type: 'email',
                                    message: t('Please provide a valid email')
                                }
                                ]


                            }}
                                            placeholder={t('login')}/>
                        </Col>
                        <Col xs={24}>
                            <PasswordFormField
                                type={'password'}
                                form={{
                                    style: {marginBottom: 4},
                                    label: t('Password'),
                                    name: 'password',
                                    rules: [{
                                        required: true,
                                        message: t('Password is required')
                                    },
                                    ]
                                }}
                                placeholder={t('Password')}/>
                            <NavLink
                                to={`${GLOBAL_PATHS.RECOVER_PASSWORD}`}>{t('Forgot password?')}</NavLink>
                        </Col>

                        <Col xs={24} style={{marginTop: 24}}>
                            <FormAntd.Item style={{marginBottom: 0}}>
                                <AuthButton block
                                            type="primary"
                                            htmlType="submit"
                                            loading={isLoading}
                                            data-cy="submit-button">{t('Access')}</AuthButton>
                            </FormAntd.Item>
                        </Col>
                    </Row>

                </Form>
                <Row>
                    <Col xs={24}>
                        <Divider>{t("or")}</Divider>
                    </Col>
                    <Col xs={24}>
                        <GoogleLogin style={{display: 'none'}}
                                     refButton={googleButton}
                                     onSuccess={GoogleSuccessAuthentication}
                                     onFailure={GoogleErrorAuthentication}
                                     disabled={blockedGoogle}/>
                        <SSOStyledButton block loading={isLoading}
                                         disabled={blockedGoogle}
                                         onClick={() => {
                                             googleButton.current.querySelector('div[role="button"]').click()
                                         }} icon={
                            <GoogleIcon/>}>{t('Access with')} Google</SSOStyledButton>
                    </Col>
                    <Col xs={24} style={{marginTop: 8}}>

                        <MicrosoftLogin
                            debug={false}
                            withUserData={true}
                            forceRedirectStrategy={false}
                            graphScopes={["user.read"]}
                            clientId={MICROSOFT_APP_CLIENT_ID}
                            useLocalStorageCache={true}
                            authCallback={MicrosoftHandleLogin}
                        >
                            <SSOStyledButton block loading={isLoading}
                                             icon={
                                                 <LogoMicrosfot/>}>{t('Access with')} Microsoft</SSOStyledButton>
                        </MicrosoftLogin>
                    </Col>
                    <Col xs={24} style={{marginTop: 8}}>
                        <SSOStyledButton block loading={isLoading}
                                         onClick={() => history.push(GLOBAL_PATHS.MAGIC_LINK)}
                                         icon={
                                             <LinkOutlined/>}>{t('Access with magic link')}</SSOStyledButton>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <NavLink
                            to={`${GLOBAL_PATHS.REGISTER}`}>{t('Don\'t you have an account')}? {t('Create an account')}</NavLink>
                    </Col>
                </Row>
            </LayoutLogin>
        </>

    );
}