import React from 'react';
import { Space, Typography } from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import styled from "styled-components";
import DisplayTextElements from '../../../../components/misc/DisplayTextElements';
import { getTimeLimitType } from '../../../../utils/Utils';
import { DatetimeFormatByTimezone } from '../../../../utils/dates';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const StyledClientText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`

const StyledBoldText = styled(Text)`
    font-size: 16px;
    font-weight: 700;
`;

function ProgramBasicInfo({ program, hideClient = false }) {
    const {t} = useTranslation();
    
    return (
        <Space direction="vertical" size={1}>
            {!hideClient &&
                <Space>
                    <UserAvatar data={{ picture: program?.profile }} />
                    <StyledClientText type="secondary">{program?.client_name}</StyledClientText>
                </Space>
            }
            <StyledBoldText>{program?.name}</StyledBoldText>
            {program?.first_phase_ending &&
                <DisplayTextElements title={t("REGISTRATIONS UP UNTIL")}
                    content={DatetimeFormatByTimezone(program?.first_phase_ending)}
                    type={getTimeLimitType(program?.days_left)} />
            }
        </Space>
    );
}

export default ProgramBasicInfo;