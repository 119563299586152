import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {
    CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons'
import {Col, Modal, Row, Tooltip, Typography} from "antd";
import {FlexContainer} from "../authentication/StyledComponents";
import {
    neutral_3, polar_green_6, neutral_4, sunset_orange_4, dust_red_4
} from "../../utils/Colors";
import {ASSESSMENT} from "../../utils/Constants";
import {useTranslation} from "react-i18next";
import RemixIcon from "../data_display/RemixIcon";
import api from "../../services/api";
import {ToastNotification} from "../feedback/ToastNotification";
import * as ConstantsMessage from "../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import Button from '../data_entry/Button'
import {usePermissionContext} from "../../context/PermissionContext";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import ModalConfirm from "../../../components/ModalConfirm";
import ConfirmModal from "./ConfirmModal";
import TextArea from "../data_entry/TextArea";

const Container = styled.div`
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${({
                             border_color,
                             actived
                         }) => actived ? 'transparent' : border_color};
    //min-width: 270px;
    //max-width: 270px;
    transition: 0.1s;
    box-shadow: ${({actived}) => actived ? '0px 2px 8px 0px rgba(0,0,0,0.1)' : 'none'};
    background-color: ${({
                             actived,
                             background_color
                         }) => actived ? background_color : 'white'};


    svg {
        color: ${({border_color}) => border_color};
    }


`

const StyledText = styled(Typography.Text)`

    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    padding-left: 8px;


`
const Score = styled.div`
    border-radius: 8px;
    border: 1px solid ${neutral_3};
    padding: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${({color}) => color};
`

const ActionButton = styled(Button)`
    border-radius: 8px;
    //padding: 8px;
    height: 56px;
    width: 100%;
    border: 1px solid ${neutral_4} !important;
    color: black !important;
    background: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    font-size: 12px;
    //cursor: ;

    ${({disabled}) => {
        if (disabled) {
            return `cursor: not-allowed; 
            background: ${neutral_4};`
        }
    }}
    i, svg {
        font-size: 16px;
    }

    :hover {
        border-color: ${neutral_4};
        background: ${neutral_4} !important;
    }
`


const STATUS_BORDER_COLORS = {
    [ASSESSMENT.NOT_ANSWERED]: neutral_4,
    [ASSESSMENT.ANSWERED]: neutral_4,
    [ASSESSMENT.SENDED]: polar_green_6,
    [ASSESSMENT.INVALID]: dust_red_4,
}

const STATUS_ICONS = {
    [ASSESSMENT.NOT_ANSWERED]: <RemixIcon remixIconName={"ri-time-line"}/>,
    [ASSESSMENT.ANSWERED]: <RemixIcon remixIconName={"ri-time-line"}/>,
    [ASSESSMENT.SENDED]: <CheckCircleOutlined/>,
    [ASSESSMENT.INVALID]: <CloseCircleOutlined/>,
}

export default function CardAssesmentWithActions({
                                                     id,
                                                     status,
                                                     application,
                                                     onChangeStatus,
                                                     actived = false,

                                                     score,
                                                     ...props
                                                 }) {
    const {t} = useTranslation()
    const border_color = STATUS_BORDER_COLORS[status]
    const {hasProgramAdminOrManagerPermission} = usePermissionContext()
    const [openConfirmInvalidateModal, setOpenConfirmInvalidateModal] = useState()
    const [isLoading, setIsLoading] = useState()
    const [justification, setJustification] = useState('')
    const icon = STATUS_ICONS[status]
    const isSended = status === ASSESSMENT.SENDED
    const isInvalid = status === ASSESSMENT.INVALID
    const {updateLayoutContent} = useLayoutContext()

    function handleReturn() {

        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                content: t("The evaluator must redo the evaluation again."),

                onOk: async () => {
                    try {
                        setIsLoading(true);
                        const response = await api.post(`assessments/${id}/rollback_evaluation`, null, {version: 'v2'});
                        onChangeStatus?.()
                        ToastNotification(t(ConstantsMessage.SUCCESS));
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, null);
                    } finally {
                        setIsLoading(false);
                    }
                },
                okText: t('Return evaluation'),
                cancelText: t('Cancel')

            }
        )
    }

    useEffect(() => {
        if (!openConfirmInvalidateModal) {
            setJustification('')
        }
    }, [openConfirmInvalidateModal])

    async function handleInvalidate() {

        try {
            setIsLoading(true);
            const response = await api.post(`assessments/${id}/invalidate_evaluation`, {justification: justification}, {version: 'v2'});
            onChangeStatus?.()
            ToastNotification(t(ConstantsMessage.SUCCESS));
        } catch (error) {
            BuildFieldErrorsDict(error, null, null);
        } finally {
            setIsLoading(false);
        }

    }

    function handleDelete() {

        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                content: t("Do you really want to delete this evaluation?"),
                onOk: async () => {
                    try {
                        setIsLoading(true);
                        const response = await api.delete(`assessments/${id}`, {version: 'v2'});
                        onChangeStatus?.()
                        ToastNotification(t(ConstantsMessage.SUCCESS));
                    } catch (error) {
                        BuildFieldErrorsDict(error, form.setFields, null);
                    } finally {
                        setIsLoading(false);
                    }
                },
                okText: t('Yes'),
                cancelText: t('Cancel')

            }
        )
    }

    return (
        // manager or admin

        <Container
            // background_color={background_color}
            direction={'column'}

            border_color={border_color}
            actived={actived} {...props}>
            <FlexContainer>
                {icon}
                <StyledText>{application?.name}</StyledText>
                <Score
                    color={isSended ? polar_green_6 : 'black'}>
                    {score ? score : '?'}
                </Score>
            </FlexContainer>
            <Row gutter={[16, 16]} style={{marginTop: 16}}>
                <Col xs={8}>
                    <ActionButton loading={isLoading} onClick={handleReturn}
                                  disabled={isLoading || isSended || !hasProgramAdminOrManagerPermission}>
                        {!isLoading && <RemixIcon
                            remixIconName={'ri-arrow-go-back-line'}/>}
                        <span>{t('Return')}</span>
                    </ActionButton>
                </Col>
                <Col xs={8}>
                    <Tooltip title={props?.justification}>
                        <ActionButton loading={isLoading}
                                  onClick={() => setOpenConfirmInvalidateModal(true)}
                                  disabled={isLoading || !isSended || !hasProgramAdminOrManagerPermission || isInvalid}>
                        {!isLoading &&
                            <RemixIcon remixIconName={"ri-forbid-line"}/>}
                        <span>{t('Invalidate')}</span>
                        </ActionButton>
                    </Tooltip>
                </Col>
                <Col xs={8}>
                    <ActionButton loading={isLoading} onClick={handleDelete}
                                  disabled={isLoading || !hasProgramAdminOrManagerPermission}>
                        {!isLoading && <RemixIcon
                            remixIconName={"ri-delete-bin-6-line"}/>}
                        <span>{t('Delete')}</span>
                    </ActionButton>
                </Col>
            </Row>
            <ConfirmModal onConfim={handleInvalidate}
                          disabledOKButton={!justification}
                          body={<Row gutter={[8, 8]}>

                              <Col
                                  xs={24}><span>{t("Do you really want to invalidate this evaluation?")}</span></Col>
                              <Col xs={24}><Typography.Title
                                  level={5}>{t('Reason for invalidation')}</Typography.Title></Col>
                              <Col xs={24}><TextArea value={justification}
                                                     onChange={({target: {value}}) => setJustification(value)}/></Col>
                          </Row>} okText={t('Yes')}
                          open={openConfirmInvalidateModal}
                          setOpen={setOpenConfirmInvalidateModal}/>

        </Container>

    );
}