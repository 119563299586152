import {useTranslation} from "react-i18next";

import React from "react";
import {Form as FormAnt} from "antd";
import SimpleCollapse from "../misc/SimpleCollapse";
import SwitchFormField from "../data_entry/form/SwitchFormField";
import CheckboxWithSwitchShowSelected
    from "../data_entry/CheckboxWithSwitchShowSelected";
import CustomTag from "../tag/CustomTag";
import CheckboxWithSwitchFormField
    from "../data_entry/form/CheckboxWithSwitchFormField";
import {APPLICATION_STATUS_CHOICES} from "../../utils/Choices";
import ApplicationTagStatus from "../tag/ApplicationTagStatus";


export default function FilterStatusApplication({
                                                    form,
                                                }) {
    const {t} = useTranslation()

    return (
        <SimpleCollapse title={'Status'}>


            <CheckboxWithSwitchFormField
                form={form}
                options={APPLICATION_STATUS_CHOICES.map(option => (
                    {
                        ...option,
                        label: <ApplicationTagStatus
                            application={{
                                status: option.value,
                                status_display: t(option.label)
                            }}
                        />,
                    }))}/>


        </SimpleCollapse>


    )
}