import React from "react";
import {Avatar, Button, Flex, Typography} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SectionWithInput from "../../../home/InnovationPanel/Sections/SectionWithInput";
import UserAvatar from "../../../../components/misc/UserAvatar";
import {useHistory} from "react-router-dom";
import {useUserContext} from "../../../../context/UserContext";


const {Text} = Typography;

const TopSection = styled.div`
    background-color: white;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PublicHeader = ({program, ...props}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {isAuthenticated} = useUserContext()
    return (
        <>
            <TopSection {...props}>
                <Flex gap={4} align="center">
                    <UserAvatar size="small" data={{picture: program?.profile, username: program?.name}}/>
                    <Text type="secondary"
                          style={{fontSize: 16, fontWeight: 500}}>{program?.program?.client_name}</Text>
                </Flex>
                {!isAuthenticated() &&
                    <Flex gap={8}>
                        <Button type="default" onClick={() => history.push("/login")}>{t("Login")}</Button>
                        <Button type="primary"
                                onClick={() => history.push("/register")}>{t("Create an account")}</Button>
                    </Flex>
                }
            </TopSection>
            <SectionWithInput blur={false} imgSrc={program?.program?.banner}/>
        </>
    );
};

export default PublicHeader;
