import React from 'react';
import {Flex, Typography} from "antd";
import styled from "styled-components";
import NewIdeaButton from "./NewIdeaButton";
import {useTranslation} from "react-i18next";

const WriteIdeaContainer = styled(Flex)`
    width: 100%;
    border-radius: 8px;
    background: white;
    padding: 16px;
    gap:8px;
`

function NewIdeaSection() {
    const {t} = useTranslation()


    return (
        <>
            <WriteIdeaContainer vertical={true}>
                <Typography.Text>{t('Contribute with:')}</Typography.Text>
                <NewIdeaButton/>
            </WriteIdeaContainer>
        </>
    );
}

export default NewIdeaSection;