import {useProgramDetailContext} from "../context/ProgramDetailContext";
import {Col, Row} from "antd";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import Empty from "../../../../components/data_display/Empty";
import {useState} from "react";
import ChallengeDrawer from "../../../../components/other/challenge/ChallengeDrawer/ChallengeDrawer";
import Loading from "../../../../components/feedback/Loading";
import ChallengeCard from "../components/ChallengeCard";


export default function ChallengesProgramTab() {
    const { isDesktop } = useSystemBreakpoint()
    const { program, challenges } = useProgramDetailContext()
    const [openChallengeDrawer, setOpenChallengeDrawer] = useState(false);
    const [challengeIndex, setChallengeIndex] = useState(null);

    function handleChallengeDrawer(challenge) {
        setChallengeIndex(challenge);
        setOpenChallengeDrawer(true);
    }

    return (
        <>
            <Row gutter={[32, 16]}>
                {
                    challenges?.length > 0 &&
                    challenges?.map((challenge, index) => (
                        <Col span={isDesktop ? 8 : 24} style={{ display: "flex", flexDirection: "column" }} key={index}>
                            <ChallengeCard program={program} challenge={challenge} onClick={() => handleChallengeDrawer(challenge)} />
                        </Col>
                    ))
                }
                {challenges?.length === 0 ?
                    <div style={{ flexGrow: "1", display: "grid", placeContent: "center" }}>
                        <Empty />
                    </div> : 
                    !challenges && <Loading />
                }
            </Row>
            <ChallengeDrawer challengeIndex={challengeIndex} open={openChallengeDrawer} setOpen={setOpenChallengeDrawer} />
        </>
    );
}
