import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    ArrowRightOutlined,
    CalendarOutlined,
    FilterOutlined, PieChartOutlined,
} from "@ant-design/icons";
import {GamificationCard} from "./StyledComponents";
import {Modal, Space, Typography} from "antd";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import Button from "../../../../../components/data_entry/Button";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import {neutral_5} from "../../../../../utils/Colors";
import RemixIcon from "../../../../../components/data_display/RemixIcon";


const ButtonContainer = styled(GamificationCard)`
    width: 100%;

    font-weight: 500;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    :hover {
        color: var(--primary_btn_text_color);
        background: var(--primary_btn_color);
    }
`


const Header = styled.div`
    margin: -24px -24px 24px -24px;
    background: black;
    //height: 165px;
    display: flex;
    align-items: start;
    gap: 4px;
    padding: ${({isPhone}) => isPhone ? '42px 32px 42px 32px' : '65px 32px 65px 32px'};

    & > * {
        color: white;
        font-size: ${({isPhone}) => isPhone ? '24px' : '30px'};
    }

    h1 {
        margin-bottom: 0;
    }
`

export default function HelpButton({}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const {isPhone} = useSystemBreakpoint()

    function onClose() {
        setOpen(false)
    }

    return (
        <>
            <ButtonContainer
                onClick={() => setOpen(true)}>{t('Understand gamification in SOLV')}
                <ArrowRightOutlined/></ButtonContainer>

            <Modal open={open}
                   width={854}
                   onCancel={onClose}
                   closable={false}
                // okText={'Close'}
                   footer={<FlexContainer
                       justify={'end'}><Button
                       onClick={onClose}>{t('Close')}</Button></FlexContainer>}

            >

                <Header isNotPhone={isPhone} style={{alignItems: 'center'}}>
                    {!isPhone && <RemixIcon remixIconName={'ri-game-line'}/>}
                    <h1>{t('How does gamification work in SOLV?')}</h1>
                </Header>

                <FlexContainer gap={24} direction={'column'}>
                    <span>{t('Our gamification system was created to engage you in strategic actions within the platform. It consists of Points, Rankings and Campaigns, which offer several ways to earn rewards and compete with other users. Check out how each feature works below.')}</span>
                    <HelpCard
                        title={<FlexContainer gap={6} align={'center'} >
                            <RemixIcon remixIconName={'ri-copper-coin-line'}/>
                            <Typography.Title
                                level={5}>{t('Points')}</Typography.Title>
                        </FlexContainer>}
                        // img={InnovationDateImage}
                        text={t('You earn points by performing actions on the platform, such as signing up for programs, passing stages, or evaluating contributions. Each action generates a specific amount of points, which will be counted after a validation period. This period allows managers to review and rectify actions, ensuring that the score is fair and accurate. In addition, it is possible to add points manually when necessary. Remember: rectified points do not count towards the ranking.')}/>

                    <HelpCard
                        title={<FlexContainer gap={6}  align={'center'}>
                                 <RemixIcon remixIconName={'ri-trophy-line'}/>
                            <Typography.Title
                                level={5}>{t('Rankings')}</Typography.Title>
                        </FlexContainer>}
                        text={t('Leaderboards provide a clear view of user performance. You can create general or program-specific leaderboards, and schedule new leaderboards to be displayed as needed. Points earned through your actions, including those earned through campaigns, are automatically counted toward the leaderboard. Prizes can be set and distributed based on positions achieved.')}/>

                    <HelpCard
                        title={<FlexContainer gap={6}  align={'center'}>
                                 <RemixIcon remixIconName={'ri-timer-flash-line'}/>
                            <Typography.Title
                                level={5}>{t('Campaigns')}</Typography.Title>
                        </FlexContainer>}
                        text={t('Campaigns are temporary events that increase the points you earn from certain actions or programs by offering an additional bonus during a specific period. These bonuses are added to your total score and also contribute to your ranking.')}/>

                </FlexContainer>
            </Modal>
        </>


    )
}

const CardCointainer = styled.div`
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    border: ${({hasBorder}) => hasBorder ? `1px solid ${neutral_5}` : 'none'};

`
const Title = styled.div`
    & * {
        margin-bottom: 0px !important;
    }

    margin-bottom: 8px;

`

const Image = styled.img`
    object-fit: cover;
    max-width: 100%;
`

function HelpCard({title, text, img, hasBorder = true, ...props}) {
    const {isDesktop} = useSystemBreakpoint()
    return (
        <CardCointainer gap={8} hasBorder={hasBorder} {...props}>
            <Title>{title}</Title>
            <FlexContainer align={'start'} wrap={isDesktop ? 'noWrap' : 'wrap'}
                           gap={8}>
                <Typography.Text>
                    {text}
                </Typography.Text>
                {img && <Image src={img}/>}
            </FlexContainer>
        </CardCointainer>
    )
}

