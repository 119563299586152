import React, {useEffect, useState} from "react";
import {Button, Col, Form as FormAnt, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import Drawer from './../../../../../global/components/layout/Drawer';
import Form from "../../../../../global/components/data_entry/form/Form";
import InputFormField from "../../../../../global/components/data_entry/form/InputFormField";
import SelectFormField from "../../../../../global/components/data_entry/form/SelectFormField";
import TextAreaFormField from "../../../../../global/components/data_entry/form/TextAreaFormField";
import PreviewIndicatorCard from "./PreviewIndicatorCard";
import api from "../../../../../global/services/api";
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import IndicatorProjectCard from "../../ProjectManagement/components/IndicatorProjectCard";
import IndicatorExtraDescription from "./IndicatorExtraDescription";
import {useDeactivateIndicator} from "./useDeactivateIndicator";
import loading from "../../../../../global/components/feedback/Loading";
import {Link} from "react-router-dom";
import {any} from "../../../../../global/utils/Utils";


function IndicatorForm({form, handleFormIndicator, currentIndicator}) {
    const {t} = useTranslation();
    const indicatorName = FormAnt.useWatch("name", form)
    const indicatorType = FormAnt.useWatch("indicator_preset", form)

    const rules = [{
        required: true,
        message: t('This field is require')
    }]

    const indicatorsTypeChoices = [
        {value: "rating", label: t("Rating 0 to 10 with higher being better")},
        {value: "rating_inverse", label: t("Rating 0 to 10 with higher being worse")},
        {value: "currency", label: t("Currency")},
        {value: "number", label: t("Numeric")},
        {value: "percent", label: t("Percent")},
    ]

    return (
        <Form form={form} onFinish={handleFormIndicator}>
            <Row gutter={[12, 16]}>
                <Col xs={24}>
                    <InputFormField form={{
                        name: 'name',
                        label: t("Indicator Name"),
                        rules: [...rules],
                        help: t("Give the indicator a name")
                    }} data-cy="name-input" />
                </Col>
                <Col xs={24}>
                    <SelectFormField form={{
                        name: 'indicator_preset',
                        label: t("Indicator Type"),
                        help: t("Select the type of the indicator"),
                        initialValue: "rating",
                        disable: true,
                        rules: [...rules],
                    }}  options={indicatorsTypeChoices}
                        disabled={!!currentIndicator?.id}
                        data-cy="indicator-type-select"
                    />
                </Col>
                <Col xs={24}>
                    <TextAreaFormField form={{
                        name: 'description',
                        label: t("Indicator Description"),
                        rules: [...rules],
                        help: t("Give the indicator a description"),
                        initialValue: t("A design system for enterprise-level products. Create an efficient and enjoyable work experience.")
                    }}  data-cy="description-input"/>
                </Col>
                <Col xs={24}>
                    {indicatorType &&
                        <PreviewIndicatorCard indicatorType={indicatorType} indicatorName={indicatorName}/>
                    }
                </Col>
            </Row>
            <Button htmlType="submit" hidden></Button>
        </Form>
    )
}

export default function IndicatorDrawer({
                                            openDrawer,
                                            setOpenDrawer,
                                            onClose,
                                            currentIndicator,
                                            fetchUpdateIndicator,
                                            handleIndicatorActivation
                                        }) {

    const {t} = useTranslation()
    const [form] = FormAnt.useForm()
    const [formulaForm] = FormAnt.useForm()
    const {confirmDeactivateIndicator} = useDeactivateIndicator()
    const [loading, setLoading] = useState(false)
    const hasAverageFormula = currentIndicator?.components && Array.isArray(currentIndicator.components)
    ? currentIndicator.components.some((component) => component["is_average"])
    : false;

    const handleFormIndicator = async (data) => {
        try {
            setLoading(true)
            if (!currentIndicator.id) {
                await api.post("/project_management/client_indicators", data)
                ToastNotification(t("Indicator created successfully!"))
            } else {
                await api.patch(`/project_management/client_indicators/${currentIndicator.id}`, data)
                ToastNotification(t("Indicator updated successfully!"))
            }
            onClose ? onClose() : setOpenDrawer(false)
        } catch (error) {
            ToastNotification(error, 'warning');
        } finally {
            setLoading(false)
        }
    }

    const handleIndicatorDeactivation = () => {
        confirmDeactivateIndicator({indicator: currentIndicator, handleConfirm: handleIndicatorActivation});
    };

    const ActivationButtons = () => {
        if (!currentIndicator.id) {
            return <></>
        }
        return (
            currentIndicator?.is_active ?
                <Button size="small" danger onClick={handleIndicatorDeactivation} data-cy="deactivate-button">{t("Deactivate")}</Button> :
                <Button size="small" type="primary" onClick={handleIndicatorActivation} data-cy="activate-button">{t("Activate")}</Button>
        )
    }


    const SaveButton = () => {
        // const hasFormulas = currentIndicator?.tabs?.length > 0
        const formSubmit = hasAverageFormula ? formulaForm.submit : form.submit
        if (!currentIndicator?.is_default || hasAverageFormula) {
            return (
                <Button type="primary" onClick={formSubmit} loading={loading} data-cy="save-button">{t("Save")}</Button>
            )
        }
        return <></>
    }

    useEffect(() => {
        if (currentIndicator?.id) {
            const {name, indicator_preset, description} = currentIndicator
            form.setFieldsValue({name, indicator_preset, description})
        } else {
            form.resetFields()
        }
    }, [currentIndicator]);

    return (
        <Drawer
            title={currentIndicator?.id ? t("Update Indicator") : t("New Indicator")}
            footer={
                <Space style={{display: "flex", justifyContent: "end"}}>
                    {!currentIndicator?.id &&
                        <Link to={`/support?description=${t('I need help creating indicators.')}`}>
                            <Button secondary>{t("Need help?")}</Button>
                        </Link>
                    }
                    <ActivationButtons/>
                    <SaveButton/>
                </Space>
            }
            open={openDrawer}
            setOpen={setOpenDrawer}
            destroyOnClose={true}
            loading={!currentIndicator}
        >
            {currentIndicator?.is_default ?
                <IndicatorProjectCard
                    indicator={currentIndicator}
                    type={!currentIndicator?.is_active ? "error" : undefined}
                    extraDescription={<IndicatorExtraDescription indicator={currentIndicator} formulaForm={formulaForm}
                                                                 fetchIndicator={fetchUpdateIndicator}/>}
                /> :
                <IndicatorForm
                    form={form}
                    handleFormIndicator={handleFormIndicator}
                    currentIndicator={currentIndicator}
                />
            }
        </Drawer>
    );
}

