import React from 'react';
import {Card, Flex, Typography} from "antd";

const {Title} = Typography;

function ProgramDescriptionCard({title, description}) {

    return (
        <Card>
            <Flex vertical={true}>
                <Title level={4}>{title}</Title>
                {description && description}
            </Flex>
        </Card>
    );
}

export default ProgramDescriptionCard;