import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Table from "../../../components/data_display/Table";

import TruncateText from "../../../components/responsive/TruncateText";

import {DateFormatByUserTimezone} from "../../../utils/dates";
import useTable from "../../../hooks/useTable";
import ApplicationTagStatus
    from "../../../components/tag/ApplicationTagStatus";



export default function SolutionsTable({search, bodyRequest, onRowClick}) {
    const {t} = useTranslation();


    const {
        tableData, isLoading, tableParams, handleTableChange,
    } = useTable({
        url: `solutions/by_client`,
        hasPagination: true,
        body: bodyRequest,
        extraParams:{
            search:search
        },
        config: {version: "v2"},
            dependenceRequest:[search,JSON.stringify(bodyRequest)]
    })


    const columns = [
        {
            title: t('Code'),
            dataIndex: 'code',
            sorter: 'code',
            key: 'code',
            ellipsis: true,
            width: "30px",
            render: (value) => <span
                data-cy={`solution-code-${value}`}>{value}</span>
        },
        {
            title: t('Title'), dataIndex: 'name',
            sorter: 'name',
            key: 'name',
            render: (value) => <div style={{width: 500}}>
                <TruncateText>{value || "-"}</TruncateText></div>
        },
        {
            title: t('Status'),
            dataIndex: 'status_display',
            // sorter: 'is_aproved',
            key: 'status_display',
            ellipsis: true,
            width: '170px',
            render: (value, record) => {
                if (!value) return '-';
                // const statusColor = getApplicationStatusColor(record);
                // return <SolvTag color={statusColor} style={{ textTransform: 'capitalize'}}>{value}</SolvTag>
                return <ApplicationTagStatus application={record}/>
            }
        },
        {
            title: t('Owner'),
            dataIndex: 'team.name',
            sorter: 'team__name',
            key: 'team',
            ellipsis: true,
            width: '150px',
        },
        {
            title: t('Step'),
            dataIndex: 'current_phase.title',
            sorter: 'current_phase__title',
            key: 'current_phase',
        },
        {
            title: t('Program'),
            dataIndex: 'program',
            sorter: 'program__name',
            key: 'program',
            render: (value) => <div style={{width: 250}}>
                <TruncateText>{value?.name}</TruncateText></div>
        },
        {
            title: t('Registration date'),
            dataIndex: 'date_submited',
            sorter: 'date_submited',
            key: 'date_submited',
            ellipsis: true,
            width: '100px',
            render: (value) => {
                return DateFormatByUserTimezone(value)
            }
        }
    ]

    return (

        <Table columns={columns} onChange={handleTableChange}
               tableParams={tableParams}
               loading={isLoading}
               noScroll
            // showSizeChanger={false}
               onRowClick={onRowClick}
               dataSource={tableData}
               data-cy="solution-table"
        />

    )
}
