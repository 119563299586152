import { Card, Flex, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import DangerousHtmlPreviewer from "../../../components/data_display/DangerousHtmlPreviewer";
import PhasesTimeLine from "../../../components/data_display/PhasesTimeline";
import { useUserContext } from '../../../context/UserContext';
import useApplication from "../../../hooks/useApplication";
import useFacebookPixel from '../../../hooks/useFacebookPixel';
import useSystemBreakpoint from '../../../hooks/useSystemBreakpoint';
import api from "../../../services/api";
import { page_background } from '../../../utils/Colors';
import ChallengeCard from "./components/ChallengeCard";
import ProgramBasicInfo from "./components/ProgramBasicInfo";
import ProgramDescriptionCard from "./components/ProgramDescriptionCard";
import ProgramSuggestButton from "./components/ProgramSuggestButton";
import PublicFooter from './components/PublicFooter';
import PublicHeader from './components/PublicHeader';
import ChallengeDrawer from "../../../components/other/challenge/ChallengeDrawer/ChallengeDrawer";

const {Text, Title} = Typography;

const PageContainer = styled(Flex)`
    border-radius: 24px;
    min-height: 100vh;
    margin-top: -60px;
    z-index: 2;
    position: relative;
    background: ${page_background};
    padding: ${({ isPhone }) => isPhone ? '12px' : '16px'};
`;

const HorzScrollableContainer = styled.div`
    --padding: 0px;
    overflow-x: auto;
    width: 100%;
    display: flex;
    gap: 32px;
    padding: 0 var(--padding);
    scroll-behavior: smooth;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    
    .ant-card {
        flex-shrink: 0;
        width: ${({ isPhone }) => isPhone ? '80vw' : '28vw'};
    }
`;


export default function ProgramPublic(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {isPhone} = useSystemBreakpoint();
    const {slug} = useParams();
    const {isAuthenticated} = useUserContext();
    const [program, setProgram] = useState()
    const [challenges, setChallenges] = useState([])
    const {handleApplyChallenge} = useApplication();
    const [challengeIndex, setChallengeIndex] = useState(undefined)

    
    const fetchProgram = async () => {
        try {
            let response = await api.get(`/public/program/${slug}`);
            setProgram(response.data);
        } catch (error) {
            if (error.response?.status === 404) {
                history.push('/404/', {broken_url: window.location.host + location.pathname})
            }
            console.error(error)
        }
    };

    const fetchChallenges = async () => {
        try {
            let response = await api.get(`/public/program/${slug}/challenges`);
            setChallenges(response.data);
        } catch (error) {
            // BuildFieldErrorsDict(error, null, false)
        }
    };

    const handleRedirect = (challenge) => {
        if (isAuthenticated()) {
            void handleApplyChallenge(challenge)
        } else {
            const queryParams = new URLSearchParams({apply: "yes"})
            if (challenge?.program_type === "challenge"){
                queryParams?.set("challenge", challenge.id)
            }
            let url = `/login?next=/available-programs/detail/${program?.id}&${queryParams}`
            history.push(url)
        }
    }

    useFacebookPixel({id: program?.programsettings?.tracking_configuration?.facebook_pixel_id})

    useEffect(async () => {
        await void fetchProgram()
        await fetchChallenges()
    }, [slug]);

    return (
        <>
            <PublicHeader program={program} id="#prevent-horizontal-scrolling" />
                <PageContainer vertical={true} isPhone={isPhone} gap={16}>
                    <Flex justify="space-between" gap={isPhone && 8} vertical={isPhone}>
                        <ProgramBasicInfo program={program} hideClient={true}/>
                        <ProgramSuggestButton program={program?.program} challenge={program?.program}
                            onClick={() => handleRedirect(program?.program)}
                        />
                    </Flex>

                    <HorzScrollableContainer id="#horz-scrollable-container">
                        {challenges?.map((challenge, index) => (   
                            <ChallengeCard style={{flexGrow: 0, width: isPhone ? '80vw' : '28vw'}} program={program} challenge={challenge} key={index}
                                suggestButtonOnClick={() => handleRedirect(challenge)}
                                onClick={() => setChallengeIndex(challenge)}
                            />
                        ))}
                    </HorzScrollableContainer>    
                    
                    <Flex gap={16} vertical={true}>
                        <ProgramDescriptionCard
                            title={t("About the program")}
                            description={<DangerousHtmlPreviewer content={program?.description}/>}
                        />

                        <ProgramDescriptionCard
                            title={t("Type of registrations accepted")}
                            description={
                                <Text>{program?.program?.team_types_display?.map(type => t(type)).join(", ")}</Text>
                            }
                        />
                    </Flex>
                    <Card>
                        <Flex vertical={true} gap={4}>
                            <Title level={4}>{t("Schedule")}</Title>
                            <PhasesTimeLine programId={program?.id} program={program?.program}/>
                        </Flex>
                    </Card>
                </PageContainer>
            <PublicFooter/>
            <ChallengeDrawer challengeIndex={challengeIndex} open={!!challengeIndex} setOpen={setChallengeIndex} />
        </>
    );
}