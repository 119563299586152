import React from "react";
import EasyCrop from "../../../components/EasyCrop/EasyCrop";
import styled from "styled-components";
import banner_default from "../../../assets/img/1024x240.png";
import {ToastNotification} from "../feedback/ToastNotification";
import {Upload} from "antd";
import {useTranslation} from "react-i18next";


const StyledBannerContainer = styled.div`
    min-width: 100%;
    height: ${({height}) => height || "240px"};
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-radius: 8px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    label {
        margin-left: 130px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #f8f8f8;
        padding: 4px 8px;
    }
`

// OBS: usar o componente BannerUploaderNewVersion
export default function BannerUploader({
                                           img,
                                           imgDefault,
                                           ratio,
                                           onChange,
                                           objectFit,
                                           MAX_SIZE=2,
                                           styleProps
                                       }) {
    ratio = ratio || 1920 / 840
        const {t} = useTranslation()
    return (
        <StyledBannerContainer
            data-cy="banner-uploader-container" {...styleProps}>
            <EasyCrop
                borderRadius={0}
                img={img ? img : imgDefault || banner_default}
                id='banner'
                onUploadImage={(file) => {
                    const validSize = file.size / 1024 / 1024 <= MAX_SIZE;
                    if (!validSize) {
                        ToastNotification(t(`File size limit is: {{size}}MB`,{size: MAX_SIZE}), 'error')
                        return ;
                    }
                    onChange?.(file)
                }}
                ratio={ratio}
                objectFit={objectFit}
            />
        </StyledBannerContainer>
    );
}
