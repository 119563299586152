import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import api from "../global/services/api";
import {WarningOutlined} from "@ant-design/icons";
import {BuildFieldErrorsDict} from "../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../modules/project/components/layout/context/LayoutContext";
import ExtraHeader from "../modules/project/components/layout/ExtraHeader";
import {
    ToastNotification
} from "../global/components/feedback/ToastNotification";
import {neutral_1} from "../global/utils/Colors";
import InputFormField
    from "../global/components/data_entry/form/InputFormField";
import {Col, Row, Form as FormAnt} from "antd";
import TextAreaFormField
    from "../global/components/data_entry/form/TextAreaFormField";
import Form from "../global/components/data_entry/form/Form";
import Button from "../global/components/data_entry/Button";
import {
    FlexContainer
} from "../global/components/authentication/StyledComponents";
import {SUCCESS} from "../global/utils/fileWithMessage";
import useQuery from "../global/hooks/useQuery";
import InformationCard
    from "../global/components/data_display/InformationCard";
import useDefaultGetRequest from "../global/hooks/useDefaultGetRequest";
import {RANKING_STATUS} from "../global/utils/Constants";

const StyledBodySup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${neutral_1};
  //height: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;


`


export function Support(props) {
    const {t} = useTranslation();
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const query = useQuery()

   const {
        data:support,
        isLoading: isLoadinSupportMail
    } = useDefaultGetRequest({
        url: `/called/support_info`,
    })


    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const data = await form.getFieldsValue()
            let response = await api.post(`/called`, {...data, reason:'others'});
            if (response.status === 201) {
                ToastNotification(t(SUCCESS));
                form.resetFields()
            }
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        } finally {
            setIsLoading(false);
        }
    };

    const {initializeLayout,} = useLayoutContext();
    const pageLayoutSettings = {
        extraHeaderContent: <ExtraHeader left={t("Support")} goBack={false}/>
    }
    useEffect(() => {
        initializeLayout(pageLayoutSettings);
    }, []);

    const rules = [{
        required: true,
        message: t('This field is required')
    }]
    return (
        <StyledBodySup>
            {/*<*/}
            {!isLoadinSupportMail && !support?.support_mail && <InformationCard icon={<WarningOutlined />} title={t('Platform technical support')}
                                 text={t('You are sending a message to the SOLV developers. We cannot provide information about programs or applications. If you have questions about the programs or your application, please contact the program manager.')}/>}
            <Form onFinish={handleSubmit} form={form} style={{marginTop: 16}}>
                <Row gutter={[12, 0]}>
                    <Col xs={24} md={12}>
                        <InputFormField form={{
                            name: 'email_called',
                            label: t("Email"),
                            rules: [...rules, {
                                    type: 'email',
                                    message: t('Please provide a valid email')
                                }]
                        }}/>
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormField form={{
                            name: 'number_phone',
                            label: t("Phone number"),
                            rules: rules
                        }}/>
                    </Col>
                    <Col xs={24}>
                        <TextAreaFormField form={{
                            name: 'description_called',
                            label: t("Description"),
                            rules: rules,
                            initialValue: query.get("description")
                        }}/>
                    </Col>
                    <Col xs={24}>
                        <FlexContainer align={'end'}>
                            <Button htmlType="submit"
                                    style={{marginLeft: 'auto'}}
                                    loading={isLoading}>{t("Send")}</Button>
                        </FlexContainer>

                    </Col>
                </Row>
            </Form>
        </StyledBodySup>


    );
}