import Drawer from "../../../../../global/components/layout/Drawer";
import {Badge, Button, Col, Divider, Form, Grid, Row, Space, Tabs, Typography} from "antd";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import IndicatorCheckpointCardFactory from "./IndicatorCheckpoint/IndicatorCheckpointCardFactory";
import CheckpointProjectIndicatorsList from "../../../components/CheckpointProjectIndicatorsList";
import DatePickerLocal from "../../../../../global/components/data_entry/DatePickerLocal";
import DetailsCollapse from "../../../components/DetailsCollapse";
import CKEditor, {SIMPLE_EDITOR_OPTIONS} from "../../../components/CKEditor";
import api from "../../../../../global/services/api";
import {useStatusReportContext} from "../context/StatusReportContext";
import {convertToFormData, getFiles} from "../../../utils/utils";
import {AttachmentDraggerUploader} from "../../../../../global/components/data_entry/AttachmentUploader";
import { useTranslation } from "react-i18next";
import DrawerTabs from "../../../../../global/components/layout/DrawerTabs";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import DangerousHtmlPreviewer from './../../../../../global/components/data_display/DangerousHtmlPreviewer';
import AutomaticIndicatorList from './AutomaticIndicatorList';
import FileIconLinks from "../../../../../global/components/misc/FileIconLinks";
import HiddenIndicatorList from "./HiddenIndicatorList";

const DrawerContent = styled(Row)`
  height: 100%;

  background: #FAFAFA;
  position: relative;

  .disabled * {
    pointer-events: none;
    opacity: 0.97;
  }

`;

const StyledColumn = styled(Col)`
  padding: 32px;
  height: 100%;
  overflow: auto;
`;

const DividerLine = styled(Divider)`
  position: absolute;
  height: 100%;
  left: 50%;
  margin: auto;
`;

const StyledDescriptionItem = styled(Form.Item)`
    .ant-form-item-control {
        flex-direction: column-reverse;
        padding-bottom: 24px;
    }

    .ant-form-item-label {
        padding-bottom: 0;
    }
`;

const {Text, Link} = Typography;

function IndicatorResponsesContent({statusReport}) {
    const {fetchStatusReport} = useStatusReportContext();
    return <>
        <Space direction="vertical" size="middle" style={{width: "100%"}} data-cy="indicator-checkpoint-card-factory">
            {statusReport.indicator_responses.map((indicatorResponse, key) => (
                <IndicatorCheckpointCardFactory indicatorResponse={indicatorResponse} key={key}/>
            ))}
            <HiddenIndicatorList statusReport={statusReport} fetchStatusReport={fetchStatusReport} />
            <CheckpointProjectIndicatorsList statusReport={statusReport} />
            <AutomaticIndicatorList statusReport={statusReport} />
        </Space>
    </>;
}

function RecordContent({statusReport}) {
    const {statusReportForm, setFormIsValid} = useStatusReportContext();
    const { t } = useTranslation();

    const [attachments, setAttachments] = useState(getFiles(statusReport.attachments));

    useEffect(() => {
        statusReportForm.resetFields();
    }, [statusReport.id]);
    
    const handleFilesChange = (data) => {
        setAttachments(getFiles(data));
    }

    return <>
        <Form form={statusReportForm} layout="vertical" onFieldsChange={() => setFormIsValid(true)}>
            <Form.Item name="date" label={t("Checkpoint Date")} rules={[{required: true}]} initialValue={statusReport.date}>
                <DatePickerLocal style={{width: "100%"}} data-cy="checkpoint-date-select"/>
            </Form.Item>
            {(statusReport.is_editable && statusReport.previous_status_report) &&
                <Form.Item label={t("Previous Checkpoint")}>
                    <DetailsCollapse
                        children={
                            // Tratado no backend
                            <DangerousHtmlPreviewer 
                                style={{color: "var(--font-secondary-color)"}} 
                                content={statusReport?.previous_status_report?.description} 
                            />
                        }/>
                </Form.Item>
            }
            <StyledDescriptionItem
                name="description" 
                validateTrigger=""
                label={t("Description V2")} 
                initialValue={statusReport.description} 
                rules={[{required: true, message: t("Please, provide a description.")}]}
                data-cy="description-input"
                help={<FileIconLinks files={attachments} />}
            >
                <CKEditor disabled={!statusReport.is_editable} height={"250px"} {...SIMPLE_EDITOR_OPTIONS}  />
            </StyledDescriptionItem>
            <Form.Item
                label={<Space size={4}>
                    <Text>{t("Add attachment")}</Text>
                    <Text type="secondary" style={{fontSize: 12}}>(pdf, png, jpeg, jpg) 10MB</Text>
                </Space>}
                name="attachments"
                valuePropName={"fileList"}
                initialValue={attachments}
            >
                <AttachmentDraggerUploader data-cy="checkpoint-attachment-upload" onChange={handleFilesChange} />
            </Form.Item>
        </Form>
    </>;
}

function ColumnsLayout({statusReport}) {
    return <>
        <StyledColumn span={12} data-cy={"left-indicator-tabs-column"}>
            <IndicatorResponsesContent statusReport={statusReport} />
        </StyledColumn>
        <DividerLine type="vertical" />
        <StyledColumn span={12}>
            <RecordContent statusReport={statusReport} />
        </StyledColumn>
    </>;
}

function MobileLayout({statusReport}) {
    const {formIsValid} = useStatusReportContext();
    const { t } = useTranslation();

    return (
        <DrawerTabs defaultActiveKey={"1"} tabBarStyle={{}}>
            <Tabs.TabPane tab="Indicators" key="1">
                <IndicatorResponsesContent statusReport={statusReport}/>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab={<Space direction="horizontal">{t("Record")} {!formIsValid && <Badge count={1}/>}</Space>} key="2">
                <RecordContent statusReport={statusReport}/>
            </Tabs.TabPane>
        </DrawerTabs>
    );
}

function StatusReportDrawer({statusReport, show, setShow, handleSave}) {

    const [title, setTitle] = useState("");
    const screens = Grid.useBreakpoint();
    const { t } = useTranslation()

    useEffect(() => {
        if (!statusReport) {
            setTitle("");
            return;
        }
        if (!statusReport.is_edited) {
            setTitle(t("New Checkpoint"));
        } else {
            setTitle(`Checkpoint Nº${statusReport.order}`);
        }
    }, [statusReport]);


    return <>
        <Drawer
            hideTopBorder={!screens.md}
            width={1024}
            title={title}
            open={show}
            onClose={() => setShow(false)}
            footer={
                <div style={{textAlign: "end"}}>
                    {(statusReport && statusReport.is_editable) && <Button type="primary" onClick={handleSave} data-cy="submit-button">{t("Save")}</Button>}
                </div>
            }
            bodyStyle={{position: "relative", padding: 0, background: "#FAFAFA"}}
        >
            {statusReport ?
                <DrawerContent>
                    {screens.md ?
                        <ColumnsLayout statusReport={statusReport}/> :
                        <MobileLayout statusReport={statusReport}/>
                    }
                </DrawerContent>
                :
                <LoadingFull />}
        </Drawer>
    </>;
}

export default StatusReportDrawer;