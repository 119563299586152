import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import InfoCard from "../../../../global/components/data_display/InfoCard";
import {Button, Col, Form, Modal, Row, Space, Tooltip, Typography} from "antd";
import {ClockCircleOutlined, DownloadOutlined, InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import api from "../../../../global/services/api";
import {useParams} from "react-router-dom";
import StatusReportDrawer from "./components/StatusReportDrawer";
import {StatusReportContextProvider} from "./context/StatusReportContext";
import {useLayoutContext} from "../../components/layout/context/LayoutContext";
import {useProjectManagementContext} from "./context/ProjectManagementContext";
import {convertToFormData, limitKeys} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {useSolvModalContext} from "../../components/modal/SolvTourModalList";
import StatusReportTable from "../../components/tables/StatusReportTable";
import useEventListener from "../../../../global/hooks/useEventListener";
import {ToastNotification} from "../../../../global/components/feedback/ToastNotification";
import RemixIcon from "../../../../global/components/data_display/RemixIcon";
import {generateExportReport, REPORTS,} from "../../../../global/pages/database/hooks/useExportDatabase";
import data from "bootstrap/js/src/dom/data";

const {confirm} = Modal;

function ProjectManagementCheckpointsPage() {
    const [nextCheckpointInfo, setNextCheckpointInfo] = useState({});
    const [statusReportForm] = Form.useForm();
    const [showStatusReportDrawer, setShowStatusReportDrawer] = useState(false);
    const [statusReport, setStatusReport] = useState(undefined);
    const {openNotification} = useLayoutContext();
    const {setLateCheckpointStatus, fetchProject, project} = useProjectManagementContext();
    const [formIsValid, setFormIsValid] = useState(false);
    const {id} = useParams();
    const {t} = useTranslation();
    const {openTourModalByIdentifier} = useSolvModalContext();
    const [isExportLoading, setIsExportLoading] = useState(false);

    const fetchNextCheckpointInfo = async () => {
        try {
            const response = await api.get(`project_management/project/${id}/next_checkpoint_info`);
            setNextCheckpointInfo(response.data);
            setLateCheckpointStatus(response.data["overdue"]);
        } catch (error) {
            if (error.response?.data?.errors) {
                ToastNotification(error.response.data.errors, 'error')
                setShowStatusReportDrawer(false);
            }
        }
    };

    const fetchCurrentStatusReport = async () => {
        try {
            const response = await api.get(`/project_management/project/${id}/current_status_report`);
            setStatusReport(response.data);
        } catch (error) {
            if (error.response?.data?.errors) {
                ToastNotification(error.response.data.errors, 'error');
                setShowStatusReportDrawer(false);
            }
        }
    };

    const fetchStatusReport = async (id) => {
        try {
            const response = await api.get(`/project_management/status_reports/${id}`);
            setStatusReport(response.data);
        } catch (error) {
            if (error.response?.data?.errors) {
                ToastNotification(error.response.data.errors, 'error');
                setShowStatusReportDrawer(false);
            }
        }
    };


    const handleCreateStatusReport = () => {
        setStatusReport(undefined);
        void fetchCurrentStatusReport();
        setShowStatusReportDrawer(true);
    };

    const handleUpdateStatusReport = ({id}) => {
        setStatusReport(undefined);
        void fetchStatusReport(id);
        setShowStatusReportDrawer(true);
    };

    const saveIndicatorResponses = async () => {
        const payload = {
            "indicator_responses": JSON.stringify(limitKeys(statusReport["indicator_responses"], ["id", "stored_value", "scaled_value", "tags"])),
        };
        await api.patch(`/project_management/status_reports/${statusReport["id"]}/update_responses`, payload);
    };

    const handleResetStatusReport = async () => {
        setStatusReport(undefined);
        void fetchCurrentStatusReport();
    };

    const confirmStatusReportReset = (errorMessage) => {
        confirm({
            title: t("Confirm"),
            cancelButtonProps: {style: {display: "none"}},
            content: errorMessage,
            onOk: () => handleResetStatusReport(),
        });
    };
    const handleException = async (exception) => {
        setFormIsValid(false);
        if (exception.response) {
            if (exception.response.data.code.includes(409)) {
                void confirmStatusReportReset(exception.response.data.errors);
            } else {
                await fetchNextCheckpointInfo();
                openNotification({type: "error", message: exception.response.data.errors});
            }
        } else {
            openNotification({type: "error", message: t("Could'nt update checkpoint")});
        }
    };
    const handleSaveStatusReport = async () => {
        try {


            await statusReportForm.validateFields();
            await saveIndicatorResponses();

            const data = await statusReportForm.getFieldsValue()
            const payload = convertToFormData(data, false);
            await api.patch(`/project_management/status_reports/${statusReport.id}`, payload);

            try {
                if (project?.forecast_mode === "automatic") {
                    await api.post(`project_management/forecast_milestone/update_forecast_values_by_project/${project?.id}`)
                }
            } catch (error) {

            }
            setShowStatusReportDrawer(false);
            openNotification({type: "success", message: t("Saved Checkpoint")});
        } catch (exception) {
            void handleException(exception);
        } finally {
            await fetchNextCheckpointInfo();
            await fetchProject();
            dispatchEvent(new CustomEvent('update-status-reports'));
        }
    };

    const reloadStatusReport = async () => {
        const id = statusReport.id;
        setStatusReport(undefined);
        fetchStatusReport(id);
    }

    useEffect(() => {
        if (!id) return;
        void fetchNextCheckpointInfo();
    }, [id]);

    const handleExport = () => {
        const event = {
            detail: {
                setIsLoading: setIsExportLoading,
                body: {
                    project: id,
                }
            }
        }
        generateExportReport(REPORTS.STATUS_REPORT_LIST)(event);
    }


    useEventListener('update-status-report', reloadStatusReport);

    return (
        <>
            <StatusReportContextProvider
                statusReport={statusReport}
                setStatusReport={setStatusReport}
                statusReportForm={statusReportForm}
                formIsValid={formIsValid}
                setFormIsValid={setFormIsValid}
                fetchStatusReport={fetchStatusReport}
            >
                <StatusReportDrawer
                    statusReport={statusReport}
                    show={showStatusReportDrawer}
                    setShow={setShowStatusReportDrawer}
                    handleSave={handleSaveStatusReport}
                />
            </StatusReportContextProvider>
            <Space direction="vertical" size={24} style={{width: "100%"}}>
                <InfoCard bordered={false}>
                    <Row justify="space-between">
                        <Col style={{display: "flex", alignItems: "center"}}>
                            <Space size={4}>
                                <Typography.Text strong>
                                    {nextCheckpointInfo["date"]}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                    <Space size={4}>
                                        <ClockCircleOutlined/>
                                        {nextCheckpointInfo["days_left"]}
                                    </Space>
                                </Typography.Text>
                            </Space>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handleCreateStatusReport}
                                data-cy="new-checkpoint-button"
                            >
                                <PlusOutlined/>
                                {t("New Checkpoint")}
                            </Button>
                        </Col>
                    </Row>
                </InfoCard>
                <SolvCollapse
                    contentStyle={{overflow: "auto"}}
                    bordered={true}
                    title={t("Previous Checkpoints")}
                    active={true}
                    locked={true}
                    content={
                        <StatusReportTable
                            projectId={id}
                            onRowClick={handleUpdateStatusReport}
                        />
                    }
                    renderExtra={
                        <Space>
                            <Tooltip title={t("Export")}>
                                <Button
                                    loading={isExportLoading}
                                    type="default"
                                    onClick={handleExport}
                                >
                                    <RemixIcon remixIconName={"ri-download-cloud-line"}/>
                                </Button>
                            </Tooltip>
                            <InfoCircleOutlined
                                onClick={() =>
                                    openTourModalByIdentifier("project_checkpoint")
                                }
                            />
                        </Space>
                    }
                />
            </Space>
        </>
    );
}

export default ProjectManagementCheckpointsPage;