import React from 'react';
import WideSpace from "../../../../components/layout/WideSpace";
import {Card, Row, Typography} from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import {ArrowRightOutlined} from "@ant-design/icons";
import TruncateText from "../../../../components/responsive/TruncateText";
import DisplayTextElements from "../../../../components/misc/DisplayTextElements";
import {DatetimeFormatByTimezone} from "../../../../utils/dates";
import {getTimeLimitType} from "../../../../utils/Utils";
import {toTitleCase} from "../../../../../modules/project/utils/utils";
import useApplication from "../../../../hooks/useApplication";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Button from "../../../../components/data_entry/Button";


const {Text} = Typography;

const StyledBoldFixedLines = styled(TruncateText)`
    font-size: 16px;
    font-weight: 700;
`;

const StyledButton = styled(Button)`
    width: 100%;
`

const StyledCardContentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
`

const StyledClickableCard = styled(Card)`
    /* transition: 500ms; */
    //max-width: 375px;
    
    :hover {
        filter: brightness(96%);
        /* scale: 1.02; */
    }
`


function ChallengeCard({challenge, program, onClick, suggestButtonOnClick, style}) {

    const programInfo = program?.program;
    const {t} = useTranslation();


    return (
        <StyledClickableCard style={{flexGrow: 1, ...style}} bodyStyle={{height: "100%"}}>
            <StyledCardContentContainer>
                <WideSpace direction="vertical" onClick={onClick} style={{cursor: "pointer"}}>
                    <Row justify="space-between">
                        <UserAvatar size="large" data={{picture: program?.client_profile, username: program?.name}}/>
                        <ArrowRightOutlined/>
                    </Row>
                    <TruncateText><Text type="secondary">{programInfo?.name} </Text></TruncateText>
                    <StyledBoldFixedLines>{challenge.name}</StyledBoldFixedLines>
                    <TruncateText><Text type="secondary">{challenge.description}</Text></TruncateText>
                    {programInfo?.first_phase_ending &&
                        <DisplayTextElements title={t("REGISTRATIONS UP UNTIL")}
                                             content={DatetimeFormatByTimezone(programInfo?.first_phase_ending)}
                                             type={getTimeLimitType(programInfo?.days_left)}/>
                    }
                    <DisplayTextElements
                        title={t("TYPE OF SUBMISSION")}
                        content={programInfo?.team_types_display}
                        mode="list"
                    />
                </WideSpace>
                <Row>
                    <StyledButton
                        onClick={() => suggestButtonOnClick()}>
                        {`${t("Suggest")} ${t(toTitleCase(programInfo?.application_name_choice))}`}
                    </StyledButton>
                </Row>

            </StyledCardContentContainer>
        </StyledClickableCard>
    );
}

export default ChallengeCard;