import React from 'react';
import {neutral_2, neutral_4} from "../../utils/Colors";
import styled from "styled-components";
import {FlexContainer} from "../authentication/StyledComponents";
import UserAvatar from "../misc/UserAvatar";
import {Space, Typography} from "antd";
import {
    AppstoreOutlined,
    CalendarOutlined,
    FlagOutlined
} from "@ant-design/icons";
import {DatetimeFormatByTimezone} from "../../utils/dates";
import ApplicationTagStatus from "../tag/ApplicationTagStatus";
import {PHASE_DISPLAY} from "../../utils/Constants";
import {useTranslation} from "react-i18next";

const Container = styled.div`
    background: white;
    width: 100%;
    padding: 16px;
    display: flex;
    border-radius: 6px;
    gap: 16px;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        background: ${neutral_2};
    }
`
const Description = styled.span`
    font-weight: 500;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`


const Name = styled.span`
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

const InfoContainer = styled.div`
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid ${neutral_4};
    background: ${neutral_2};
    //flex: 1;
    min-width: 200px;
    width: min-content;
    display: flex;
    gap: 8px;

    & > *:not(:first-child) {
        font-size: 12px;
        white-space: nowrap;
    }

    & .name {
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        white-space: nowrap;
    }
`


export default function SolutionCard({
                                         application,
                                         onClick,
                                     }) {
    const {t} = useTranslation();
    let name = application?.team?.name || application?.author_name

    return (
        <Container onClick={onClick}>
            <FlexContainer justify={'space-between'}>
                <Space>
                    <UserAvatar size={24}
                                data={{...application?.team, name: name}}/>
                    <Name>{name}</Name>
                </Space>
                <Space>
                    {application?.date_submited && <Space>

                        <CalendarOutlined/>
                        {DatetimeFormatByTimezone(application?.date_submited, false)}
                    </Space>}
                    <ApplicationTagStatus application={application}/>
                </Space>

            </FlexContainer>
            <Description>{application?.name} </Description>
            <FlexContainer wrap={'wrap'} gap={8}>
                <InfoContainer>
                    <AppstoreOutlined/>
                    <span className={'name'}>
                    {application?.program?.name}</span>
                    <Typography.Text type={'secondary'}> > </Typography.Text>
                    <Typography.Text
                        type={'secondary'}>{t(PHASE_DISPLAY[application?.current_phase.type])}</Typography.Text>

                </InfoContainer>
                {application?.challenge && <InfoContainer>
                    <FlagOutlined/>
                    <span className={'name'}>
                    {application?.challenge?.name}
                    </span>

                </InfoContainer>}
            </FlexContainer>
        </Container>
    );
}

