import {useEffect, useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {useHistory} from "react-router-dom";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {useTranslation} from "react-i18next";

//todo depois mudar nome para useDefaultRequest
export default function useDefaultGetRequest({
                                                 url,
                                                 params,
                                                 setData,
                                                 makeRequest = true,
                                                 redirectRoute,
                                                 hasPagination = false,
                                                 config = {}, body,
                                                 dataDefault = '',
                                                 dependence = []
                                             }) {

    const [isLoading, setIsLoading] = useState(false)
    const [_data, _setData] = useState(dataDefault)
    const history = useHistory()
    const {t} = useTranslation()
    useEffect(() => {
        if (makeRequest) fetchData()
        return () => {
            _setData(null)
        }
    }, [...dependence, makeRequest])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            let response
            if (body) {
                response = await api.post(url, body, {params: params, ...config},);
            } else {
                response = await api.get(url, {params: params, ...config},);
            }

            if (hasPagination) {
                _setData(response.data.results)
                setData?.(response.data.results)

            } else {
                _setData(response.data)
                setData?.(response.data)
            }
        } catch (error) {
            if (redirectRoute && error.response?.status === 404) {
                history.push(redirectRoute)
                ToastNotification(t('Not found'), 'error')
            } else {
                BuildFieldErrorsDict(error, null, false)
            }
        } finally {
            setIsLoading(false);
        }
    }

    return {
        data: _data,
        isLoading,
        setIsLoading,
        fetchData
    }
}