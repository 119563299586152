import React, {useEffect, useState} from "react";
import api from "../../../../global/services/api";
import {
    BuildFieldErrorsDict,
} from "../../../../global/utils/Utils";
import {CardContainer} from "../../../../global/components/misc/CardContainer";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import {Alert, Modal, Skeleton, Space, Tag, Typography} from "antd";
import {Row, Col, Form as FormAnt} from 'antd'
import Form from "../../../../global/components/data_entry/form/Form";
import InputFormField
    from "../../../../global/components/data_entry/form/InputFormField";
import Button from "../../../../global/components/data_entry/Button";
import Empty from "../../../../global/components/data_display/Empty";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../../global/components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../global/utils/fileWithMessage";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {SUCCESS} from "../../../../global/utils/fileWithMessage";


const FORM_NAME = {

    EMAIL: 'email',
}


export default function SectionEmail() {

    const [form] = FormAnt.useForm()
    const {t} = useTranslation();
    const [emailEnvironment, setEmailEnvironment] = useState();
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                setIsLoadingData(true)
                const response = await api.get(`client_emails`);
                setShowEmailInput(!!response.data[0])
                setEmailEnvironment(response.data[0]);

                if (response.data[0]) {
                    form.setFieldValue('email', response.data[0].email)
                }
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }finally {
                setIsLoadingData(false)
            }
        };
        fetchEmail()
    }, []);

    function handleSubmit() {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t("Save Email"),
                content: t("Are you sure you want to save this email?"),
                onOk: async () => {
                    try {
                        setIsLoading(true);
                        const data = await form.getFieldValue()
                        const response = await api.post(`client_emails`, data);
                        setEmailEnvironment(response.data)
                        ToastNotification(t(ConstantsMessage.SUCCESS));
                    } catch (error) {
                        BuildFieldErrorsDict(error, form.setFields, null);
                    } finally {
                        setIsLoading(false);
                    }
                },
                okText: t('Yes'),
                cancelText: t('No')

            }
        )
    }

    function handleDelete() {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t("Delete Email"),
                content: t("Are you sure you want to delete email?"),
                onOk: async () => {
                    try {
                        setIsLoading(true);
                        await api.delete(`client_emails/${emailEnvironment.id}`);
                        setEmailEnvironment(null);
                        setShowEmailInput(false);
                        ToastNotification(t(SUCCESS));
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false);
                    } finally {
                        setIsLoading(false);
                    }
                },
                okText: t('Yes'),
                cancelText: t('No')

            }
        )
    }

    async function handleFinish() {
        if (emailEnvironment) {
            handleDelete()
        } else {
            handleSubmit()
        }

    }

    return (
        <>
            {isLoadingData? <Skeleton active/> : (
                <CardContainer>
                    <Form form={form} onFinish={handleFinish}
                          disabled={isLoading}>
                        <Row gutter={[32, 32]}>
                            <Col xs={24}>
                                <FlexContainer
                                    justify={'space-between'}>
                                    <Typography.Title
                                        level={5}>{t('Email')}</Typography.Title>
                                    {emailEnvironment && <Tag
                                        color={emailEnvironment.is_verified ? 'green' : 'orange'}>{
                                        emailEnvironment.is_verified ? t('active') : t('pending')
                                    }</Tag>}

                                </FlexContainer>
                                <Typography.Text type={'secondary'}>{t('Allows you to add an email mask to emails sent by solv. If there is no registered and validated email, the default email will be solv@solv.network')}</Typography.Text>
                            </Col>


                            {showEmailInput ? (
                                <>
                                    <Col xs={24}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={24}>
                                                <InputFormField
                                                    form={{
                                                        label: t('Email'),
                                                        name: FORM_NAME.EMAIL,

                                                        rules: [{
                                                            type: 'email',
                                                            message: t('Please provide a valid email')
                                                        }]
                                                    }}/>

                                            </Col>
                                            <Col xs={24}>
                                                {emailEnvironment && !emailEnvironment?.is_verified &&
                                                    <Alert
                                                        message={
                                                            <FlexContainer
                                                                justify={'center'}>{t('This email requires confirmation of your email registration. Click on the link and confirm your email.')} </FlexContainer>}
                                                        type="warning"/>}
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col xs={24}>
                                        <FlexContainer justify={'end'}>
                                            {emailEnvironment ? (
                                                <Button
                                                    danger
                                                    type={'default'}
                                                    loading={isLoading}
                                                    htmlType={'submit'}>
                                                    {t('Delete')}
                                                </Button>) : (
                                                <Button
                                                    loading={isLoading}
                                                    htmlType={'submit'}>
                                                    {t('Save')}
                                                </Button>)}
                                        </FlexContainer>
                                    </Col>
                                </>


                            ) : (
                                <Col xs={24}>
                                    <Empty
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                        description={t('You don\'t have a registered email address yet.')}>

                                            <Button
                                                onClick={() => setShowEmailInput(true)}>
                                                {t('Register now')}
                                            </Button>

                                    </Empty>


                                </Col>
                            )}
                            {!emailEnvironment || !emailEnvironment.is_verified && <Col xs={24}>
                                <FlexContainer justify={'center'}>
                                    <Typography.Text
                                                type={'secondary'}>{t('If there is no registered and validated email address, the default email address solv@solv will be used. network')}</Typography.Text>

                                </FlexContainer>
                            </Col>}
                        </Row>
                    </Form>
                </CardContainer>
            )}

        </>
    );
}
