import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {
    blue_5, neutral_2, neutral_3, neutral_4, neutral_5,
} from "../../../../../../global/utils/Colors";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {
    PlusCircleFilled,
} from "@ant-design/icons";
import ModalCreatePhase
    from "../../../../../../components/Phases/ModalCreatePhase";
import HeaderPhaseColumn from "./HeaderPhaseColumn";
import BodyPhaseColumn from "./BodyPhaseColumn";
import {PHASE} from "../../../../../../global/utils/Constants";

const Container = styled.div`
    background: ${neutral_2};
    border-radius: 16px;
    width: 271px;
overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    

`

const CreatePhaseContainer = styled.div`
    cursor: pointer;
    padding: 16px;
    height: 150px;
    border-radius: 4px;
    color:#00000073;
    
 position: relative;
    &:hover {
        background: ${neutral_5};
    }

`
const CreatePhaseButton = styled(PlusCircleFilled)`
   
    color:#00000073;
    

    &:hover {
        background: ${neutral_5};
    }

`
const CreatePhaseLabel = styled.div`
    font-size: 14px;
    color: #00000073;
    white-space: nowrap;
    position: absolute;
    top:26px;
    left: -60%;
    transform: rotate(90deg) translateX(50%);


`


export default function PhaseColumn({phase,isConfidencial, ...props}) {
    const {t} = useTranslation();
    const [showCreatePhase, setShowCreatePhase] = useState(false)

    const getUpdateUrl = () => {
        switch (phase.type){
            case PHASE.INFORMATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/update`
            case PHASE.APPLICATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/database/applications`
            case PHASE.EVALUATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/evaluators`
        }
    }

    const updateUrl = getUpdateUrl()


    if(!phase.id) return null
    return (
        // <div style={{height: '100%'}}>
        <FlexContainer style={{height: '100%'}} align={'start'}>
            <Container open={phase.is_open}>
                <HeaderPhaseColumn phase={phase} updateUrl={updateUrl}/>
                <BodyPhaseColumn phase={phase} isConfidencial={isConfidencial} updateUrl={updateUrl}/>
            </Container>
            <CreatePhaseContainer onClick={() => setShowCreatePhase(true)} data-cy={`create-phase-button-${phase?.id}`}>
                <CreatePhaseButton />
                <CreatePhaseLabel>{t('Create new phase')}</CreatePhaseLabel>
            </CreatePhaseContainer>

            <ModalCreatePhase open={showCreatePhase}
                              onCancel={() => setShowCreatePhase(false)}
                              phase={phase}/>


        </FlexContainer>
// </div>
    );
}

