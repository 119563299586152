import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import ExtraHeader from "../../../../modules/project/components/layout/ExtraHeader";
import { Badge } from "../../../components/data_display/Badge";
import WideSpace from "../../../components/layout/WideSpace";
import { usePermissionContext } from "../../../context/PermissionContext";
import useFacebookPixel from "../../../hooks/useFacebookPixel";
import ProgramDetailContextProvider, {
    useProgramDetailContext
} from "./context/ProgramDetailContext";
import ProgramDetailRouter from "./router/ProgramDetailRouter";


export default function ProgramDetail() {
    return (
        <ProgramDetailContextProvider>
            <_ProgramDetail/>
        </ProgramDetailContextProvider>
    )
}


function _ProgramDetail() {

    const {t} = useTranslation()
    const {initializeLayout} = useLayoutContext();
    const {url} = useRouteMatch();
    const {
        program,
        challenges,
        hasProgramPermission
    } = useProgramDetailContext()
    const {programHasChallengeMappingPermission} = usePermissionContext()
    useFacebookPixel({id: program?.program?.programsettings?.tracking_configuration?.facebook_pixel_id})

    useEffect(() => {
        program && initializeLayout(
            {
                extraHeaderContent: <ExtraHeader
                    left={t("Program Information")}/>,
                showAvatar: false,
                navbarMenuItems: [
                    {
                        label: <Link to={url}>{t("Description")}</Link>,
                        key: url
                    },
                    {
                        label: <Link
                            to={`${url}/schedule`}>{t("Schedule")}</Link>,
                        key: `${url}/schedule`
                    },
                    {
                        label: <WideSpace size={4}>
                                <Link to={`${url}/challenges`}>{t("Challenges")}</Link>
                                {challenges &&
                                    <Badge>{challenges?.length}</Badge>
                                }
                        </WideSpace>,
                        key: `${url}/challenges`,
                        hasPermission: !programHasChallengeMappingPermission(program?.program)
                    },
                    {
                        label: <Link
                            to={`${url}/messages`}>{t("Messages")}</Link>,
                        key: `${url}/messages`,
                        hasPermission: hasProgramPermission
                    },
                    {
                        label: <Link
                            to={`${url}/attachments`}>{t("Attachments")}</Link>,
                        key: `${url}/attachments`
                    },
                ]
            }
        );
    }, [program, challenges, hasProgramPermission]);

    return (
        <ProgramDetailRouter program={program?.program}/>
    );
}

