import React, {useEffect, useMemo, useState} from "react";
import api from "../../../../global/services/api";
import * as ConstantsMessage from "../../../../global/utils/fileWithMessage";
import {
    BuildFieldErrorsDict,
    generateOptionsField
} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../../global/components/feedback/ToastNotification";
import {CardContainer} from "../../../../global/components/misc/CardContainer";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import {Alert, Input, Modal, Space, Tag, Typography} from "antd";
import {APP_VERSION} from "../../../../settings";
import {Row, Col, Form as FormAnt} from 'antd'
import InputFormField
    from "../../../../global/components/data_entry/form/InputFormField";
import DomainTagsFormField
    from "../../../../global/components/data_entry/form/DomainTagsFormField";
import SwitchFormField
    from "../../../../global/components/data_entry/form/SwitchFormField";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import CollapseFormItem
    from "../../../../global/components/data_entry/CollapseFormItem";
import DomainTags from "../../../../global/components/data_entry/DomainTags";
import TransparentCollapse
    from "../../../../global/components/misc/TransparentCollapse";


export default function SectionEnvironmentGeneral({form, FORM_NAME}) {
    const {t} = useTranslation();


    return (

        <TransparentCollapse
            header={t("General")}>

            <FlexContainer gap={16} direction={'column'}>
                <CollapseFormItem
                    input={<Input/>}
                    rules={[{
                        required: true,
                        message: t('This field is required')
                    }]}
                    name={FORM_NAME.NAME}
                    label={t('Environment name')}/>

                <CollapseFormItem
                    input={<Input/>}
                    rules={[{
                        type: 'email',
                        message: t('Please provide a valid email')
                    }]}
                    name={FORM_NAME.SUPPORT_EMAIL}
                    label={t('E-mail for support')}/>

                <CollapseFormItem
                    input={<DomainTags/>}
                    help={t('Email with the above extensions will be automatically added as new employees to the company\'s environment in Solv.')}
                    name={FORM_NAME.CLIENT_DOMAINS}
                    label={t('Official company email extensions')}/>
            </FlexContainer>
        </TransparentCollapse>


    );
}
