import React, {useMemo} from 'react';
import styled from 'styled-components';
import {
    blue_1,
    blue_5,
    blue_6,
    danger, gold_6,
    neutral_5,
    neutral_7,
    secondary_color
} from "../../../../../../global/utils/Colors";
import {Badge, Dropdown, Tooltip} from "antd";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {
    AuditOutlined, ExclamationCircleOutlined,
    FileDoneOutlined, MoreOutlined, SettingOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import {useHistory, Link} from "react-router-dom";
import {PHASE} from "../../../../../../global/utils/Constants";
import {useTranslation} from 'react-i18next';
import RemixIcon
    from './../../../../../../global/components/data_display/RemixIcon';



const Title = styled.span`
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    //width:80px;
    color: ${({open}) => open ? 'black' : secondary_color};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`


const StyledMenuOutlined = styled(MoreOutlined)`
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    //background: red;

    :hover {
        background: ${neutral_5};
    }
`

const Header = styled(FlexContainer)`
    padding: 8px 8px 8px 16px;
    justify-content: space-between;
    gap: 4px;
    min-height: 38px;
    max-height: 38px;

    :last-child {
        max-width: 98px;
    }
`

export default function HeaderPhaseColumn({phase, updateUrl, ...props}) {
    const {t} = useTranslation()
    const history = useHistory()
    const IS_PHASE_INFORMATION = phase.type === PHASE.INFORMATION
    const IS_PHASE_APPLICATION = phase.type === PHASE.APPLICATION
    const IS_PHASE_EVALUATION = phase.type === PHASE.EVALUATION

    const FORM_URL = IS_PHASE_EVALUATION ? `/programs/${phase.program_id}/phases/${phase.id}/evaluateform` : `/programs/${phase.program_id}/phases/${phase.id}/form`
    const SHOW_FORM_BUTTON = !IS_PHASE_INFORMATION && !phase.has_form
    const SHOW_EVALUATOR_BUTTON = IS_PHASE_EVALUATION && phase.evaluators_count === 0

    const alertMessage = useMemo(() => {
        const messages = []
        if(SHOW_FORM_BUTTON) {
            if (IS_PHASE_EVALUATION) {
                messages.push(`${t('Evaluation form not completed')}`)
            }
            if (IS_PHASE_APPLICATION) {
                messages.push(`${t('Application form not completed')}`)
            }
        }
        if(SHOW_EVALUATOR_BUTTON) {
            messages.push(`${t('This stage does not have an allocated evaluator')}`)
        }
        return messages.reduce((previousValue, currentValue) => {
            return previousValue += `- ${currentValue};\n`
        },'')

    }, [SHOW_FORM_BUTTON, SHOW_EVALUATOR_BUTTON, IS_PHASE_EVALUATION, IS_PHASE_APPLICATION])


    function handleRedirectPageUpdate() {
        history.push(updateUrl)
    }

    function handleRedirect(url) {
        history.push(url)
    }

    const getDropdownItem = (label, link, icon) => {
        return {
            icon,
            key: link,
            label: <Link to={link}>{label}</Link>
        }
    }


    let items = [
        getDropdownItem(t('Configure phase'), `/programs/${phase.program_id}/phases/${phase.id}/update`,
            <SettingOutlined/>)
    ];

    if ([PHASE.EVALUATION, PHASE.APPLICATION].includes(phase.type)) {
        items = [
            getDropdownItem(t('Edit form'), FORM_URL, <div><RemixIcon
                remixIconName={'ri-file-edit-line'}/></div>),
            ...items
        ]
    }
    if ([PHASE.EVALUATION].includes(phase.type)) {
        items = [
            getDropdownItem(t('View evaluators'), `/programs/${phase.program_id}/phases/${phase.id}/evaluators`,
                <AuditOutlined/>),
            getDropdownItem(t('Allocate evaluators'), `/programs/${phase.program_id}/phases/${phase.id}/allocation_evaluators`,
                <UsergroupAddOutlined/>),
            getDropdownItem(t('Select applications'), `/programs/${phase.program_id}/phases/${phase.id}/application-selection`,
                <FileDoneOutlined/>),
            ...items
        ]
    }
    return (
        <Header>
            <FlexContainer style={{overflow: 'hidden', cursor: 'pointer'}}
                           gap={4} onClick={handleRedirectPageUpdate}>
                <Badge count={phase.sequence} color={open ? blue_5 : neutral_7}
                       showZero={true}/>
                <Tooltip title={phase.title}>
                    <Title open={phase.is_open}>{phase.title}</Title>
                </Tooltip>
                {alertMessage && <Tooltip trigger={'hover'} title={<span style={{whiteSpace: 'pre-wrap'}}>{alertMessage}</span>}>
                    <ExclamationCircleOutlined style={{color: gold_6}}/>
                </Tooltip>}
                <Badge overflowCount={999} count={phase.applications.length}
                       color={blue_1}
                       style={{color: blue_6, borderColor: blue_1}}
                       showZero={true}/>
            </FlexContainer>

            <FlexContainer gap={8} justify={'flex-end'}>


                <Dropdown trigger='click' menu={{items}}>
                    <StyledMenuOutlined data-cy={`config-phase-button-${phase?.id}`} />
                </Dropdown>
            </FlexContainer>
        </Header>
    );
}

