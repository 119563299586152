import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {Col, Empty, Row} from "antd";
import Loading from "../../../components/feedback/Loading";
import SolutionCard from "../../../components/other/SolutionCard";
import Pagination from "../../../components/navigation/Pagination";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";


export default function SolutionsCard({search, bodyRequest, onCardClick}) {
    const {t} = useTranslation();
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const PaginationRequest = {
        url: `solutions/by_client`,
        setIsLoading: setIsLoading,
        setData: setData,
        method: 'POST',
        // makeRequest: open && id,
        body: bodyRequest,
        extraParams: {
            search: search
        },
        config: {version: "v2"},
        dependence: [search, JSON.stringify(bodyRequest)]

    }


    return (
        <Row gutter={[16, 16]}>
            {isLoading ? <Col xs={24}><Loading/></Col> : data.length > 0 ? (
                data.map((item) => <Col xs={24} key={item.id}><SolutionCard
                    onClick={() => onCardClick(item)} application={item}
                /></Col>)
            ) : <Col xs={24}><Empty/></Col>}
            <Col xs={24}>
                <FlexContainer style={{marginTop: 32}} justify={'end'}>
                    <Pagination request={PaginationRequest}
                                showTotal={(total) => t('total_applications', {total: total})}
                                size="small"
                                // hideOnSinglePage
                    />
                </FlexContainer>
            </Col>
        </Row>


    )
}
