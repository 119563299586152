import React, {useCallback, useEffect, useRef, useState} from 'react';
import api from "../../../../../global/services/api";
import styled from "styled-components";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {useProgramContext} from "../../../../ProgramsNavigator";
import BreakLayoutSystemContent
    from "../../../../../global/components/layout/BreakLayoutSystemContent";
import PhaseColumn from "./components/PhaseColumn";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import {DragDropContext} from "react-beautiful-dnd";
import {
    Modal,
    Typography,
    Col,
    Row,
    Radio, Form as FormAnt,
} from "antd";
import {PHASE} from "../../../../../global/utils/Constants";
import ConfirmModal from "../../../../../global/components/other/ConfirmModal";
import {cookie_api} from "../../../../../global/utils/Auth";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Loading from "../../../../../global/components/feedback/Loading";

import ButtonFilterPhaseDrawer from "./components/ButtonFilterPhaseDrawer";
import HeaderTitleContainer
    from "../../../../../global/components/other/HeaderTitleContainer";
import Search from "antd/es/input/Search";

const Board = styled(FlexContainer)`
    //background-color: red;
    height: calc(100vh - ${({actionBarSize}) => actionBarSize}px - 24px);
    padding: 16px 24px 24px 24px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    display: flex;
    //}
`

export default function TabPhaseProgram() {
    const {t} = useTranslation();
    const {program} = useProgramContext()
    const [search, setSearch] = useState("");
    const COOKIE_CONFIRM_GRAB = `${program.id}_confirm_grab`
    const [phases, setPhases] = useState([]);
    const [openAlertModal, setOpenAlerModal] = useState(false);
    const [releaseDrag, setReleaseDrag] = useState();
    const [allowDrag, setAllowDrag] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const intervalIDMove = useRef()
    const boardRef = useRef()
    const {actionBarRef} = useLayoutContext()
    const [filters, setFilters] = useState({})
    const [dropResult, setDropResult] = useState()

    const fetchPhases = async () => {

        try {
            setIsLoading(true)
            const response = await api.post(`/phase_kamban/kamban_get_phases/${program.id}`, filters, {version: 'v2', params: {search}});
            setPhases(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {

            setIsLoading(false)
        }
    };
    useEffect(() => {
        if (program.id) {

            fetchPhases()
        }
    }, [program, filters, search]);


    function clearIntervalMove() {
        if (intervalIDMove.current) {
            clearInterval(intervalIDMove.current)
        }
        intervalIDMove.current = null
    }

    const handleHorizontalScroll = useCallback((e) => {
        const mousePositionX = e.layerX
        const amoutPizelTarget = 200
        const timeout = 50
        const pixels = 50

        if (mousePositionX < amoutPizelTarget) {
            if (!intervalIDMove.current) {
                intervalIDMove.current = setInterval(() => {

                    boardRef.current.scrollBy({left: -30})
                }, timeout)
            }

        } else if (mousePositionX > boardRef.current.offsetWidth - amoutPizelTarget) {
            if (!intervalIDMove.current) {
                intervalIDMove.current = setInterval(() => {

                    boardRef.current.scrollBy({left: 30})
                }, timeout)
            }
        } else {
            clearIntervalMove()
        }

        // console.log(boardRef.current)
        // boardRef.current.scrollTo(e.screenX, 0)
    }, [])

    function handleOnDragStart(result) {
        boardRef.current.addEventListener("mousemove", handleHorizontalScroll)
        let application, phase
        phases.some((_phase) => {
            phase = _phase
            application = _phase.applications.find(({id}) => id === Number(result.draggableId))
            return application
        })

        _handleInFillingApplication(application, phase)
    }

    function _handleInFillingApplication(application, phase) {
        const IS_IN_FILLING = application?.status === 'in_filling'
        if (IS_IN_FILLING) {

            const FIRST_PHASE = phase.sequence === 1
            if (FIRST_PHASE) {
                setAllowDrag(false)
                //alerta para a primeria fase
                Modal.error({
                    title: t('APPLICATION NOT SUBMITTED'),
                    content: t('Applications that were not sent by the applicant cannot proceed to the next stage. Request the complete form to be filled out and sent.'),
                    onOk: () => setAllowDrag(true)
                });

            } else if (!cookie_api.get(COOKIE_CONFIRM_GRAB)) {
                //alerta para qualquer fase exceto primeira, OBS: so aparece se o usuario ainda nao confimou o modal
                setAllowDrag(false)
                setOpenAlerModal(true)

            }
        }
    }

    function validateChangePhase(application, oldPhase, newPhase) {
        return api.post(`/phase_kamban/kamban_change_application_phase/${application.id}/${oldPhase.id}/${newPhase.id}`, null, {version: 'v2'});
    }

    function handleOnDragEnd(result) {
        boardRef.current.removeEventListener("mousemove", handleHorizontalScroll)
        clearIntervalMove()
        const {destination, source} = result

        const isDropInvalido = destination?.droppableId === source.droppableId || !destination || !allowDrag

        if (isDropInvalido) {
            return
        }

        const applicationID = Number(result.draggableId)

        const newPhases = []

        phases.forEach((phase) => {

            const phaseSourceID = Number(source.droppableId)
            const isSource = phaseSourceID === phase.id

            const phaseDestinationID = Number(destination.droppableId)
            const isDestination = phaseDestinationID === phase.id

            let newApplications = [...phase.applications]

            if (isSource) {
                //remov'chamou aqui'e a aplicaçao da fase source
                newApplications = newApplications.filter(({id}) => id !== applicationID)
            } else if (isDestination) {
                //adiciona a plicaçao na fase destination
                let dropApplication
                const oldPhase = phases.find((phase) => {
                        dropApplication = phase.applications.find(({id}) => id === applicationID)
                        if (dropApplication) return phase
                    }
                )


                //troca so na exibiçao
                dropApplication.status = phase.type === PHASE.APPLICATION ? 'in_filling' : 'waiting_evaluation'
                dropApplication.status_display = phase.type === PHASE.APPLICATION ? t('in_filling') : t('Awaiting Evaluation')
                newApplications.splice(destination.index, 0, dropApplication)


                //valida a troca de phase e se tiver error da rollback
                validateChangePhase(dropApplication, oldPhase, phase).catch(() => Modal.error({
                    icon: <ExclamationCircleOutlined/>,
                    title: t("Error in phase change"),
                    content: t(
                        "Data will be reloaded",
                    ),
                    onOk: fetchPhases,
                    okText: t('Yes'),
                }))

            }

            newPhases.push({...phase, applications: newApplications})
            return phase
        })

        setPhases(newPhases)

    }


    return (
        <>
            <HeaderTitleContainer
                title={t('Application Kanban')}
                rigthContent={
                    <>
                        <Search
                            placeHolder={t('Search by name')}
                            onSearch={(value) => setSearch(value)}
                            allowClear/>
                        <ButtonFilterPhaseDrawer
                            setFilters={setFilters}
                            filters={filters}/>

                    </>
                }/>

            <BreakLayoutSystemContent className={'BreakLayout'}
                                      vertical={false}>
                {/*<div ref={boardRef}>*/}

                <DragDropContext
                    onDragStart={handleOnDragStart}
                    // onDragUpdate
                    onDragEnd={handleOnDragEnd}
                >
                    {isLoading ? <Loading/> : (
                        <Board className={'quadro'}
                               ref={boardRef}

                               actionBarSize={actionBarRef?.current?.offsetHeight}
                               align={'flex-start'}>

                            {phases.map((phase) => <PhaseColumn key={phase.id}
                                                                isConfidencial={program.is_confidential}
                                                                dropResult={dropResult}
                                                                phase={phase}/>)}


                        </Board>)}

                </DragDropContext>
                <ConfirmModal
                    disabledOKButton={!releaseDrag}
                    onClose={() => {
                        setReleaseDrag(false);
                        setAllowDrag(true)
                    }}
                    onConfim={() => {
                        cookie_api.set(COOKIE_CONFIRM_GRAB, releaseDrag)
                    }}
                    open={openAlertModal} setOpen={setOpenAlerModal}
                    body={<Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>
                                {t('APPLICATION DID NOT SUBMIT A FORM AT THIS STAGE.')}
                            </Typography.Title>
                        </Col>
                        <Col xs={24}>
                            {t('When moving applications that did not submit the form, the evaluator will not have responses from the forms in the skipped step. This action may be reversed by the kanban where the user will be notified again of the need to fill out the form, but will not have the progress made previously. Do you want to move this application?')}
                        </Col>
                        <Col xs={24} data-cy="not-show-again-radio-col">
                            <Radio
                                onChange={({target: {checked}}) => setReleaseDrag(checked)}
                            >{t("Don't show again")}</Radio>
                        </Col>
                    </Row>}/>
                {/*</div>*/}
            </BreakLayoutSystemContent>
        </>

    );
}

