import React, {createContext, useContext, useEffect, useState} from 'react';
import api from "../services/api";
import {cookie_api} from "../utils/Auth";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {refreshLanguage} from "../utils/translation";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {useLayoutContext} from '../../modules/project/components/layout/context/LayoutContext';
import useWebSocket from "../hooks/useWebSocket";
import SolvIcon from "../../assets/img/NeoCinza.svg"
import useNotifications from "../hooks/useNotification";

const UserContext = createContext();

export function UserProvider({children}) {
    const [_user, set_User] = useState(undefined);
    const [userLoading, setUserLoading] = useState(true);
    const [innovationPanelSettings, setInnovationPanelSettings] = useState(undefined);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [evaluationsCount, setEvaluationsCount] = useState(0);
    const [user_client, setUserClient] = useState(undefined);
    const [client_id, setClientID] = useState(undefined);
    const [refresh, setRefresh] = useState(false);
    const {t} = useTranslation()

    const [invitesProgram, setInvitesProgram] = useState([])
    const [invitesTeam, setInvitesTeam] = useState([])
    const [invitesAdmin, setInvitesAdmin] = useState([])
    const [invites, setInvites] = useState([])
    const [invitesCount, setInvitesCount] = useState(0)
    const {setShowWelcomeModal} = useLayoutContext();
    const [summaryCounts, setSummaryCounts] = useState({});
    const [readNotifications, setReadNotifications] = useState()


    const languageCode = _user?.language_code

    const {
        // sendJsonMessage,
        lastJsonMessage:list_export_report,
    } = useWebSocket({
        url: `user_id/${_user?.id}/client_id/${client_id}/exports?language=${languageCode}`,
        openConnection: !!_user?.id && !!client_id
    });

    const {
        // sendJsonMessage,
        lastJsonMessage: notifications,
    } = useWebSocket({
        url: `user_id/${_user?.id}/client_id/${client_id}/notifications?language=${languageCode}`,
        openConnection: !!_user?.id && !!client_id
    });

    useNotifications({notifications})

    const location = useLocation();
    const whiteList = ['/show_program'];
    const whiteListed = !!whiteList.find((item)=>location.pathname.startsWith(item));

    useEffect(async () => {
        // Primeiro request de autenticação
        void get_user()
    }, []);

    const get_user = async () => {
        try {
            setUserLoading(true)
            const response = await api.get(`/authorization/me/`);
            set_User(response.data);
            setInnovationPanelSettings(response.data.innovation_panel_settings);
            setEvaluationsCount(response.data.evaluations_count);
            setUserClient(response.data.user_client_authenticated);
            setClientID(response.data.current_client_id);
            return response.data
        } catch (error) {
            // if(error.response.data['code'] === 'not_authenticated' && history.location.pathname !== '/login'){
            //      history.push('/login')
            //     ToastNotification(t('Login Expired'), 'error')
            // }
            // BuildFieldErrorsDict(error, null, false)
        }finally {
             setUserLoading(false)
        }


    }

    function isAuthenticated(){
        return !!_user
    }


    useEffect(() => {
        // if (invites.length < invitesCount) {
        //     fetchNotification()
        // }
        setInvitesCount(invites.length)

    }, [invites])

    useEffect(() => {
        async function fetchs() {
            if (_user) {
                await fetchInvitesProgram();
                await fetchInvitesTeam();
                await fetchAdminInvites();
                // await fetchNotification();
            }
        }

        fetchs()
        // var interval = setInterval(fetchs, 360000)
        // return (() => {
        //     clearInterval(interval)
        // })
    }, [_user]);

    useEffect(() => {
        setInvites([...invitesAdmin, ...invitesProgram, ...invitesTeam])
    }, [invitesProgram, invitesTeam, invitesAdmin])

    const fetchInvitesProgram = async () => {
        try {
            const response = await api.get('/evaluator_invites/pending');

            let invites = response.data.map((invite) => {
                return {...invite, type: 'program'}
            })
            setInvitesProgram(invites)
        } catch (error) {
            // this error does not need to be handled
        }
    };

    const fetchInvitesTeam = async () => {
        try {
            const response = await api.get('/team_invites/pending/');
            let invites = response.data.map((invite) => {
                return {...invite, type: 'team'}
            })
            setInvitesTeam(invites)

        } catch (error) {
            // this error does not need to be handled
        }
    };

    const fetchAdminInvites = async () => {
        try {
            const response = await api.get('/admin_invites/pending/');
            let invites = response.data.map((invite) => {
                return {...invite, type: 'admin'}
            })
            setInvitesAdmin(invites)
        } catch (error) {
            // this error does not need to be handled
        }
    };


    useEffect(async () => {
        if (_user) {

            const fetchHasUnredMessages = async () => {

                const response = await api.get(`/topics/has_unread_messages`);
                setHasUnreadMessages(response.data.has_message);

            }
            fetchHasUnredMessages()
        }
    }, [_user]);


    useEffect(() => {

        if (_user && _user.is_valid) {
            let lastSugestUserTimezone = cookie_api.get(`user-${_user.id}_timezone`)
            lastSugestUserTimezone = lastSugestUserTimezone ? lastSugestUserTimezone : '' // cookie
            // const currentSystemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (_user.time_zone.toLowerCase() !== lastSugestUserTimezone.toLowerCase()) {
                setShowWelcomeModal(true);
            }
            refreshLanguage(_user['language_code']);
            setSummaryCounts(_user.summary_counts);
        }

    }, [_user]);

    useEffect(() => {
        try {
            if (refresh === true) {
                get_user()
                setRefresh(false);
            }

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    }, [refresh]);

    const updateSummaryCounts = (counts) => {
        setSummaryCounts((prev) => ({...prev, ...counts}))
    }

    function updateInnovationChartsPanelSettings(key, newList) {
        const newInnovationPanelSettings = {
            ...innovationPanelSettings
        }
        newInnovationPanelSettings.widgets[key] = [...newList]
        setInnovationPanelSettings(newInnovationPanelSettings)
    }

    function updateInnovationFilterChartsPanelSettings(key, newList) {
        const newInnovationPanelSettings = {
            ...innovationPanelSettings
        }
        newInnovationPanelSettings.filters[key] = [...newList]
        setInnovationPanelSettings(newInnovationPanelSettings)
    }

    return (
        <UserContext.Provider
            value={{
                _user,
                user_client,
                refresh,
                setRefresh,
                summaryCounts,
                updateSummaryCounts,
                userLoading,
                hasUnreadMessages,
                evaluationsCount,
                set_User,
                invites,
                notifications,
                setInvites,
                innovationPanelSettings,
                isAuthenticated,
                updateInnovationChartsPanelSettings,
                updateInnovationFilterChartsPanelSettings,
                list_export_report,
                readNotifications, setReadNotifications
            }}>
            {children}
        </UserContext.Provider>
    );
}


export function useUserContext() {
    return useContext(UserContext);
}
