import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    //&.ant-btn:not([disabled]) {
    //    &:not(.ant-btn-dangerous, .ant-btn-link):not(.exclusive) {
    //        color: var(--primary_btn_text_color);
    //    }
    //
    //    &.ant-btn-default:not(.ant-btn-dangerous):not(.exclusive) {
    //        color: var(--primary_btn_color);
    //        border-color: var(--primary_btn_color);
    //
    //        &:hover {
    //            color: var(--primary_btn_text_color);
    //            background-color: var(--primary_btn_color);
    //
    //            & svg, i {
    //                fill: var(--primary_btn_text_color);
    //                color: var(--primary_btn_text_color);
    //            }
    //        }
    //
    //        & svg, i {
    //            fill: var(--primary_btn_color);
    //            color: var(--primary_btn_color);
    //        }
    //    }

        //&.ant-btn-dangerous:not(.exclusive):hover {
        //    color: white;
        //    background-color: var(--ant-error-color);
        //}
    //}

    //.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    //    color: var(--primary_btn_text_color);
    //}
    
    .ant-menu-item-icon{
        fill: var(--text_color_menu) !important;
    }
    
    a {
        color: var(--primary_btn_color);
    }
    
    .debug-container{
        background: red;
    }
    
`;

export default GlobalStyle;
