import {useTranslation} from "react-i18next";

import React from "react";
import {Form as FormAnt} from "antd";
import SimpleCollapse from "../misc/SimpleCollapse";
import SwitchFormField from "../data_entry/form/SwitchFormField";
import CheckboxWithSwitchShowSelected
    from "../data_entry/CheckboxWithSwitchShowSelected";
import CustomTag from "../tag/CustomTag";
import TagsCheckboxWithSwitchShowSelected
    from "../data_entry/TagsCheckboxWithSwitchShowSelected";


export default function FilterTags({
                                       noTagName,
                                       filterName, targets
                                   }) {
    const noTagValues = FormAnt.useWatch(noTagName)
    const {t} = useTranslation()

    return (
        <SimpleCollapse title={'Tags'}>
            <SwitchFormField
                size={'small'}
                label={t('No tags only (will uncheck all tag filters)')}
                form={{
                    name: noTagName,
                    style: {marginBottom: 16}
                }}/>
            <FormAnt.Item name={filterName}>
                <TagsCheckboxWithSwitchShowSelected

                    // labelComponent={(tag) => <CustomTag {...tag}/>}
                    request={
                        {
                            url: `api/tags?${targets.reduce((acc, item) => {
                                acc += '&targets=' + item;
                                return acc;
                            }, '')}`,
                            hasPagination: true,
                            params: {
                                main_tag:true,
                                page_size: 1000,
                            },

                        }
                    }
                    disabled={noTagValues}
                />
            </FormAnt.Item>

        </SimpleCollapse>


    )
}