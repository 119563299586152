import {CloseCircleOutlined} from "@ant-design/icons";
import {Drawer as DrawerAnt, Grid, Row, Typography} from "antd";
import styled from "styled-components";
import LoadingFull from "../other/LoadingFull";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {neutral_2} from "../../utils/Colors";

const { Text } = Typography;

const StyleDrawer = styled(DrawerAnt)`
    .ant-drawer-body {
        padding: 16px 24px;
               background: ${neutral_2};
    }


    .ant-drawer-header{
        padding: 16px;
        ${({hideTopBorder})=>hideTopBorder && `border: none;`}
    }
    .ant-drawer-header-title{
        //padding: 16px 0 4px 0;
    }
  
    .ant-drawer-content-wrapper{
        max-width: 100%;
    }
    

    .ant-drawer-extra {
        padding: 9px 0 4px 16px;
    }
    
    .ant-drawer-footer{
        height: 56px;
        padding: 16px;
    }
`;

export default function Drawer({ title, isGrayBody=false,open, onClose, setOpen, children, footer, loading, container, width, hideTopBorder, destroyOnClose, background, headerPadding, ...props }) {
    const {isPhone, isTablet} = useSystemBreakpoint();

    const hideOnLoading = (component) => {
        if (loading) {
            return undefined
        }
        return component;
    }
    function handleOnClose(){
        if(setOpen){
            setOpen(false)
        }
        onClose && onClose()
    }

    return (
        <StyleDrawer
            isGrayBody={isGrayBody}
            isPhone={isPhone}
            hideTopBorder={hideTopBorder}
            getContainer={container}
            title={hideOnLoading(<div style={{ fontSize: 20 }}>{title}</div>)}
            closeIcon={null}
            closable={false}
            open={open}
            width={isTablet ? width || 520 : "100%"}
            onClose={handleOnClose}
            extra={<CloseCircleOutlined onClick={handleOnClose} style={{ fontSize: 18 }} />}
            footer={hideOnLoading(footer)}
            footerStyle={{padding: 16}}
            destroyOnClose={destroyOnClose}
            background={background}
            headerPadding={headerPadding}
            {...props}
        >
            {loading? <LoadingFull/> : children}
        </StyleDrawer>
    );
}
