import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { BuildFieldErrorsDict } from "../../../../utils/Utils";


const ProgramDetailContext = createContext({})

export default function ProgramDetailContextProvider({ children, isPublic }) {

    const {id} = useParams()
    const [program, setProgram] = useState()
    const [challenges, setChallenges] = useState()
    const [hasProgramPermission, setHasProgramPermission] = useState()
    const history = useHistory()
    const {slug} = useParams();

    const routes = {
        program: isPublic ? `/public/program/${slug}` : `v2/challenge_details/${id}/program`,
        challenges: isPublic ? `/public/program/${slug}/challenges` : `v2/programs/challenges/${id}`
    }

    const fetchProgram = async () => {
        try {
            // const url = `/challenge_details/${id}/program`
            const {data} = await api.get(routes?.program)
            setProgram(data)
            setHasProgramPermission(data.has_program_permission)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            history.push('/404')
        }
    }

    const fetchChallenges = async () => {
        try {
            // const url = `/programs/challenges/${id}`
            const { data } = await api.get(routes?.challenges)
            setChallenges(data)
            return data
        } catch (error) {

        }
    }

    const getProgramInfo = () => {
        const challenge = program; // Abstração para facilitar o entendimento
        return challenge?.program;
    }

    useEffect(() => {
        !program && void fetchProgram()
        !challenges && void fetchChallenges()
    }, [program]);

    const value = {
        program,
        challenges,
        getProgramInfo,
        hasProgramPermission,
        fetchChallenges,
        isPublic,
    }

    return (
        <ProgramDetailContext.Provider value={value}>{children}</ProgramDetailContext.Provider>
    );
}

export function useProgramDetailContext(isPublic=false) {
    return useContext(ProgramDetailContext)
}

