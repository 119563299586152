import React from 'react';
import {Line} from "@ant-design/plots";
import {Flex, Space} from "antd";
import {useProjectManagementContext} from "../../context/ProjectManagementContext";
import useDefaultGetRequest from "../../../../../../global/hooks/useDefaultGetRequest";
import Loading from "../../../../../../global/components/feedback/Loading";
import {useTranslation} from "react-i18next";
import Dot from "../../../../../../global/components/other/Dot";
import {getValueByValue} from "../../../../../../global/utils/Utils";
import {useUserContext} from "../../../../../../global/context/UserContext";
import {DateFormatUser} from "../../../../../../global/utils/dates";
import {formatNumber} from '../../../../utils/utils';
import IndicatorDashboardCard from "../../../../components/IndicatorDashboardCard";


export default function ForecastChart() {

    const {t} = useTranslation();
    const {project} = useProjectManagementContext()
    const {_user} = useUserContext();

    const {data, isLoading} = useDefaultGetRequest({
        url: `project_management/forecast_milestone/forecast_by_project/${project?.id}`,
        dependence: [project]
    })


    const showOnChart = (d) => {
        return ['total_baseline', 'total_realized', 'total_forecast'].includes(d);
    };

    // TODO: encontrar maneira correta de esconder as linhas e mostrar dados no tooltip

    const config = {
        data,
        xField: "date",
        yField: "value",
        colorField: "type",
        point: {
            // shapeField: "square",
            // sizeField: 4,
        },
        legend: {
            color: {
                itemMarker: "circle",
                itemLabelText: (d) => showOnChart(d.label) ? t(d.label) : "",
                layout: "grid",
                size: 50,
            },
        },
        interaction: {
            tooltip: {
                render: (event, {title, items}) => {
                    return (
                        <Space direction="vertical">
                            <div>{DateFormatUser(getValueByValue(items, "date", "name", "value"), _user)}</div>
                            <Flex vertical={true} gap={4}>

                                {getValueByValue(items, "total_baseline", "name", "name") === "total_baseline" &&
                                    <Space size={4}>
                                        <Dot color={"green"} size={8}/>
                                        {t(getValueByValue(items, "total_baseline", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue(items, "total_baseline", "name", "value"))}`}
                                    </Space>
                                }

                                {getValueByValue(items, "baseline", "name", "name") === "baseline" &&
                                    <Space size={4}>
                                        <Dot color={"green"} size={8}/>
                                        {t(getValueByValue(items, "baseline", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue(items, "baseline", "name", "value"))}`}
                                    </Space>
                                }

                                {getValueByValue(items, "total_realized", "name", "name") === "total_realized" &&
                                    <Space size={4}>
                                        <Dot color={"blue"} size={8}/>
                                        {t(getValueByValue(items, "total_realized", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue((items), "total_realized", "name", "value"))}`}
                                    </Space>
                                }

                                {getValueByValue(items, "realized", "name", "name") === "realized" &&
                                    <Space size={4}>
                                        <Dot color={"blue"} size={8}/>
                                        {t(getValueByValue(items, "realized", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue(items, "realized", "name", "value"))}`}
                                    </Space>
                                }

                                {getValueByValue(items, "total_forecast", "name", "name") === "total_forecast" &&
                                    <Space size={4}>
                                        <Dot color={"red"} size={8}/>
                                        {t(getValueByValue(items, "total_forecast", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue(items, "total_forecast", "name", "value"))}`}
                                    </Space>
                                }

                                {getValueByValue(items, "forecast", "name", "name") === "forecast" &&
                                    <Space size={4}>
                                        <Dot color={"red"} size={8}/>
                                        {t(getValueByValue(items, "forecast", "name", "name"))}
                                        {`R$ ${formatNumber(getValueByValue(items, "forecast", "name", "value"))}`}
                                    </Space>
                                }

                            </Flex>
                        </Space>
                    )
                }
            }
        },
        style: {
            lineWidth: 2,
        },
        scale: {
            color: {
                domain: ["total_baseline", "total_realized", "total_forecast", "forecast", "baseline", "realized",],
                range: ["green", "blue", "red", "#00000000", "#00000000", "#00000000"],
            }
        },
        axis: {
            x: {
                labelFormatter: (text) => DateFormatUser(text, _user)
            },
            y: {
                labelFormatter: (value) => `R$ ${formatNumber(value)}`
            }
        },
    };

    return (
        <IndicatorDashboardCard title={"Forecast"} bordered={false}>
            {isLoading ?
                <Flex flex={1} align="center" justify="center"><Loading/></Flex> :
                data && <Line data={data} {...config}/>
            }
        </IndicatorDashboardCard>
    );
}
