import React from 'react';
import {Divider, Flex, Typography} from "antd";
import styled from "styled-components";
import {AppstoreOutlined} from "@ant-design/icons";
import UserAvatar from "../../../../components/misc/UserAvatar";
import TruncateText from "../../../../components/responsive/TruncateText";
import MessageIconContainer from "./MessageIconContainer";
import LikeIconContainer from "./LikeIconContainer";
import EnvironmentLink from '../../../../components/link/EnvironmentLink';

const {Text} = Typography

const FeedContainer = styled(Flex)`
    width: 100%;
   
    background: white;
    border-radius: 8px;
    padding: 16px
`

const ImageContainer = styled.img`
    width: 100%;
    max-height: 327px;
    border-radius: 8px;
`

const TreeDotsButton = styled.button`
    margin-top: 8px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    padding: 0;

    &:before {
        content: '...';
        display: inline-block;
        color: inherit;
    }
`;

export default function FeedPost({post, setTopicMessage, setTopicReaction, setDrawerActiveKey }) {

    const handleDrawerOpen = (key) => {
        setTopicMessage(post?.id);
        setDrawerActiveKey(key);
    };

    return (
        <>
            <FeedContainer vertical={true} gap={8}>
                <Flex justify="space-between">
                    <Flex align="center" gap={4}>
                        <UserAvatar data={post?.team}/>
                        <Text>{post?.team?.name}</Text>
                    </Flex>
                </Flex>
                <Flex vertical={true} gap={8} onClick={() => handleDrawerOpen('1')} style={{cursor: 'pointer'}}>
                    <TruncateText lines={4}>
                        <Text>{post?.name}</Text>
                    </TruncateText>
                    {post.banner_url && <ImageContainer src={post.banner_url}/> }
                </Flex>
                <Flex justify="space-between">
                    <Flex gap={16}>
                        <LikeIconContainer topicReactions={post?.topic_reactions} object="topics" object_id={post?.topic} setTopicReaction={setTopicReaction}/>
                        <MessageIconContainer  postId={post?.id} setTopicMessage={() => handleDrawerOpen('2')} topicComments={post?.topic_comments}/>
                    </Flex>
                    <Flex align="center" gap={4} onClick={() => handleDrawerOpen('1')} style={{cursor: "pointer"}}>
                        <AppstoreOutlined/>
                        <TruncateText>
                            <Text>{post?.program.name}</Text>
                        </TruncateText>
                    </Flex>
                </Flex>
            </FeedContainer>

        </>
    );
}
