import FeedPost from "./components/FeedPost";
import {Flex, Form, Space, Typography} from "antd";
import NewIdeaSection from "./components/NewIdeaSection";
import React, {useEffect, useRef, useState} from "react";
import api from "../../../services/api";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import Empty from "../../../components/data_display/Empty";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "../../../components/feedback/Loading";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import ApplicationDrawer
    from "../../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import CarouselPro from "../../../components/misc/CarouselPro";
import CampaignFeedCard from "./components/CampaignFeedCard";
import ReactionsDrawer from "./components/ReactionsDrawer";
import CampaignsFullScreenModal from "./components/CampaignsFullScreenModal";
import BreakLayoutSystemContent
    from "../../../components/layout/BreakLayoutSystemContent";
import Search from "../../../components/data_entry/Search";
import SectionWithInput from "../InnovationPanel/Sections/SectionWithInput";
import FilterIdeaButton from "./components/FilterIdeaButton";
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";
import styled from "styled-components";
import {page_background} from "../../../utils/Colors";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";

const PageContainer = styled(FlexContainer)`
    border-radius: 24px 24px 0 0;

    margin-top: -20px;

    position: relative;
    background: ${page_background};
    padding: ${({isPhone}) => isPhone ? '16px' : '24px'};
`

const Title = styled.span`
    font-size: 14px;

`

export default function Feed() {
    const {t} = useTranslation()
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [form] = Form.useForm()
    const {refLayoutContent} = useLayoutContext();
    const hasChangedFilter = useRef(true);
    const {isPhone} = useSystemBreakpoint();
    let initialUrl = "/solutions/feed?page=1&page_size=5"
    const [nextUrl, setNextUrl] = useState(initialUrl);

    const [topicMessage, setTopicMessage] = useState()
    const [topicReaction, setTopicReaction] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [activeCampaignIndex, setActiveCampaignIndex] = useState(false)
    const [drawerActiveKey, setDrawerActiveKey] = useState('2')

    const getIsVisible = () => {
        return activeCampaignIndex !== false
    }

    const fetchCampaigns = async () => {
        try {
            const response = await api.get('/point_event?page_size=30&status=in_progress');
            setCampaigns(response.data.results)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        void fetchCampaigns();
    }, [])

    const fetchPosts = async (body) => {
        try {
            setLoading(true);

            let requestBody = {}
            let url = nextUrl
            let keepPosts = [...posts];

            if (body) {
                const {all_selected, ...statusFilters} = body;

                if (hasChangedFilter.current) {
                    url = initialUrl;
                    keepPosts = [];
                    hasChangedFilter.current = false;
                }

                if (Object.keys(statusFilters).length > 0) {
                    requestBody.status__in = Object.keys(statusFilters).filter(key => statusFilters[key]);
                }

                if (search) {
                    url = `${url}&search=${search}`
                }

            }

            const {data} = await api.post("v2" + url, requestBody);
            setNextUrl(data?.next)
            setPosts([...keepPosts, ...data?.results]);

        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        if (!loading) {
            void fetchPosts();
        }
    }

    const handleSearchOrFilter = (body) => {
        hasChangedFilter.current = true
        void fetchPosts(body)
    }


    useEffect(() => {
        async function getInfoOnSearch() {
            const data = await form.getFieldValue()
            handleSearchOrFilter(data)
        }

        getInfoOnSearch()

    }, [search])


    return (
        <BreakLayoutSystemContent>
            <SectionWithInput title={`${t("Feed")}`}>
                <FlexContainer gap={4} style={{width: '100%'}}
                               justify={'center'}>
                    <Search allowClear
                            onSearch={setSearch}
                            style={{maxWidth: '500px'}}

                            placeHolder={t('Search for the program or application name')}/>
                    <FilterIdeaButton form={form}
                                      handleSearchOrFilter={handleSearchOrFilter}/>
                </FlexContainer>

            </SectionWithInput>
            <PageContainer isPhone={isPhone} gap={24} direction={'column'}
                           align={'stretch'}>

                <Flex gap={16} vertical={true}>
                    {campaigns?.length > 0 &&
                        <Flex gap={8} vertical>
                            <Typography.Text>{t('Campaigns')}</Typography.Text>
                            <CarouselPro
                                pageSize={5}
                                autoplaySpeed={3000}
                                style={{zIndex: 0}}
                            >
                                {campaigns.map((item, index) => (
                                    <CampaignFeedCard
                                        keyId={index}
                                        item={item}
                                        campaigns={campaigns}
                                        onClick={() => setActiveCampaignIndex(index)}
                                    />
                                ))}
                            </CarouselPro>
                        </Flex>

                    }
                    <NewIdeaSection/>
                    <InfiniteScroll
                        loadMore={handleLoadMore}
                        hasMore={!!nextUrl}
                        getScrollParent={() => refLayoutContent.current}
                        useWindow={false}
                        loader={<Loading style={{height: 100}}/>}
                        style={{width: "-webkit-fill-available"}}
                    >

                        <Flex vertical={true} gap={8}>
                            <Typography.Text>{t('Ideas')}</Typography.Text>
                            {
                                posts?.map(post => (
                                    <FeedPost post={post}
                                              setTopicMessage={setTopicMessage}
                                              setTopicReaction={setTopicReaction}
                                              setDrawerActiveKey={setDrawerActiveKey}
                                    />
                                ))
                            }

                            {
                                posts?.length === 0 && !loading && <Empty/>
                            }
                        </Flex>
                    </InfiniteScroll>
                </Flex>

                <ApplicationDrawer
                    solutionId={topicMessage}
                    open={!!topicMessage}
                    setOpen={setTopicMessage}
                    showGoToProgram={false}
                    // defaultActiveKey="2"
                    defaultActiveKey={drawerActiveKey}
                    commentMode={true}
                />

                <ReactionsDrawer topic={topicReaction}
                                 setTopic={setTopicReaction}/>

                <CampaignsFullScreenModal
                    isVisible={getIsVisible()}
                    campaigns={campaigns}
                    activeIndex={activeCampaignIndex}
                    setActiveIndex={setActiveCampaignIndex}
                    onClose={() => setActiveCampaignIndex(false)}
                />
            </PageContainer>
        </BreakLayoutSystemContent>
    );
}

