import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Drawer from "../../../../components/layout/Drawer";
import {Button, Flex, Form, Typography} from "antd";
import ApplicationTagStatus from "../../../../components/tag/ApplicationTagStatus";
import SwitchFormField from "../../../../components/data_entry/form/SwitchFormField";
import CheckboxFormField from "../../../../components/data_entry/form/CheckboxFormField";
import styled from "styled-components";
import InputFormField from "../../../../components/data_entry/form/InputFormField";
import SimpleCollapse from "../../../../components/misc/SimpleCollapse";
import FilterButton from "../../../../components/button/FilterButton";

const {Text} = Typography

const StyledForm = styled(Form)`
    .ant-form-item {
        margin: 0;
    }
`

export default function FilterIdeaButton({form, handleSearchOrFilter}) {
    const {t} = useTranslation()
    const [open, setOpen] = useState()

    const options = [
        {status_display: t("Waiting evaluation"), status: "waiting_evaluation"},
        {status_display: t("Submitted"), status: "submitted"},
        {status_display: t("Approved"), status: "approved"},
        {status_display: t("Reproved"), status: "reproved"},
    ];

    const handleIndividualChange = () => {
        const {waiting_evaluation, submitted, approved, reproved} = form.getFieldsValue();
        const allChecked = [waiting_evaluation, submitted, approved, reproved].every(Boolean);
        form.setFieldsValue({
            all_selected: allChecked,
        });
    }

    const handleUniversalChange =
        (value) => {
            form.setFieldsValue({
                all_selected: value,
                waiting_evaluation: value,
                submitted: value,
                approved: value,
                reproved: value,
            });
        }

    const handleSubmitForm = (body) => {
        handleSearchOrFilter(body)
        setOpen(false)
    }

    const handleResetForm = async () => {
        form.setFieldValue("search", "")
        handleUniversalChange(true)
    }

    useEffect(() => {
        handleUniversalChange(true)
    }, []);

    return (
        <>

            <FilterButton onClick={() => setOpen(true)}/>
            <Drawer open={open} setOpen={setOpen} title={t("Filters")}
                    footer={
                        <Flex gap={10} justify={"end"}>
                            <Button
                                onClick={handleResetForm}>{t("Reset Filters")}</Button>
                            <Button type="primary"
                                    onClick={form.submit}>{t("Search")}</Button>
                        </Flex>
                    }
            >
                <StyledForm form={form} size={"small"}
                            onFinish={handleSubmitForm}>
                        {/*<SimpleCollapse title={t('Search')}>*/}
                        {/*    <InputFormField size="middle"*/}
                        {/*                    placeholder={t("Search for the program or application name")}*/}
                        {/*                    form={{name: "search"}}/>*/}
                        {/*</SimpleCollapse>*/}

                        <SimpleCollapse title={t("Filter")}>
                            <Flex vertical={true} gap={8}>
                                <Flex gap={8} align="center">
                                    <SwitchFormField size="small" form={{name: "all_selected"}}
                                                     onChange={handleUniversalChange}
                                    />
                                    <Text>{t("All")}</Text>
                                </Flex>
                                {options?.map(option => (
                                    <Flex gap={8}>
                                        <CheckboxFormField mode="single"
                                                           form={{name: option.status}}
                                                           onChange={handleIndividualChange}
                                        />
                                        <ApplicationTagStatus application={option}/>
                                    </Flex>
                                ))}
                            </Flex>
                        </SimpleCollapse>

                </StyledForm>
            </Drawer>
        </>
    );
}

