import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {FlexContainer} from "../authentication/StyledComponents";


export default function DefaultButtonDrawerFilter({
                                                      defaults,
                                                      isLoading, form,
                                                  }) {
    const {t} = useTranslation();

    function handleResetFilters() {
        form.resetFields()
        form.setFieldsValue(defaults)
    }

    return (

        <FlexContainer justify="end" gap={8}>
            <Button type={'default'}
                    onClick={() => form.resetFields()}
                    loading={isLoading}>{t("Reset filters")}</Button>
            <Button
                onClick={form.submit}
                loading={isLoading}>{t("Save")}</Button>
        </FlexContainer>


    )
}
